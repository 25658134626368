import FormAdd from '../components/admin/FormAdd';
import FormEdit from '../components/admin/FormEdit';
import { BasicInfoDataType } from '../components/admin/FormCrudBasicInfo';
import { BillingDataType } from '../components/admin/FormCrudBilling';
import { AdminFormDataContextProvider } from '../contexts/AdminFormDataContext';

export type AdminFormCrudActionType = 'add' | 'edit';

export type AdminFormCrudProps = {
  action: AdminFormCrudActionType;
  editAccess?: boolean;
  createFormButtonClicked?: boolean;
  setCreateFormButtonClicked?: (value: boolean) => void;
  basicInfoData?: BasicInfoDataType;
  setBasicInfoData?: (value: BasicInfoDataType) => void;
  setIsBasicInfoDataValid?: (value: boolean) => void;
  billingData?: BillingDataType;
  setBillingData?: (value: BillingDataType) => void;
  setIsBillingDataValid?: (value: boolean) => void;
};

export default function AdminFormCrud({ action }: { action: AdminFormCrudActionType }) {
  return (
    <AdminFormDataContextProvider>
      {action === 'add' ?
        <FormAdd />
      :
        <FormEdit />
      }
    </AdminFormDataContextProvider>
  );
}
