import { iUser } from "../contexts/UserContext";
import KeycloakService from "../services/KeycloakService";
import { AdminRoleNodes } from "./APIInterfaces";

export function GeneratePatchCmds<P>(original: P, modified: P) {
    let commands = [];
    for(let key in original){
        if(modified[key] !== undefined && modified[key] !== "" && original[key] !== modified[key])
            commands.push({op: 'replace', path: `/${key}`, value: modified[key]});
    }
    // find keys in new array that were undefined in the original
    for(let key in modified){
      if(original[key] === undefined && modified[key] !== "")
          commands.push({op: 'replace', path: `/${key}`, value: modified[key]});
    }

    return  commands;
}

export const updatePageTitle = (newTitle: string) => {
  document.title = newTitle;
};

export function detectIEOrEdge() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  var trident = ua.indexOf("Trident/");
  var edge = ua.indexOf("Edge/");
  return edge > 0 || trident > 0 || msie > 0;
}

export const isJsonString = (str: string) => {
  try {
    const json = JSON.parse(str);
    return (typeof json === 'object');
  } catch (e) {
    return false;
  }
};

export const formatDate = (inputDate: string | null, twoDigitYear: boolean = false, assignToInput: boolean = false) => {

  if (!inputDate) return "";

  const date = new Date(inputDate).toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: twoDigitYear ? '2-digit' : 'numeric'
  });

  if (date === 'Invalid Date' || isNaN(Date.parse(date))) return inputDate;

  // To assign a value to an input of type "date", the value must be formatted as YYYY-MM-DD.
  if (assignToInput) {
    const [month, day, year] = date.split('/');
    return `${year}-${month}-${day}`;
  } else {
    return date;
  }
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  // Start by removing any non-digit characters from the input string.
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  // Then use .match() to capture three groups of digits using a regular expression.
  // ^(\\d{3})(\\d{3})(\\d{4})$ looks for three sequences of digits, each of specific lengths corresponding to the parts of a US phone number.
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  // If the input string matches the pattern, return the formatted number with parentheses and a dash.
  if (match) return `(${match[1]}) ${match[2]}-${match[3]}`;
  
  // If the input does not match (e.g., it has too few or too many digits), return the "cleaned" string.
  return cleaned;
};

export const formatSSN = (ssn: string, last4: boolean = false) => {
  // Start by removing any non-digit characters from the input string.
  const cleaned = ('' + ssn).replace(/\D/g, '');

  if (last4) {
    // If wanting the "last 4" and the input's length is not 4, return the "cleaned" string.
    if (cleaned.length !== 4) return cleaned;
    return `***-**-${cleaned}`;
  } else {
    // If the input doesn't match the correct length, return the "cleaned" string.
    if (cleaned.length !== 9) return cleaned;
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 5)}-${cleaned.slice(5)}`;
  }
};

export interface ProfileCompleteProps{
  profileComplete: boolean;
  setProfileComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProfileComplete = (user: iUser | null | undefined, profileCompleteProps: ProfileCompleteProps) : boolean=> {
  console.log("ProfileComplete")
  if (user === null || user === undefined || user.id === undefined){
    console.log('user is null')
    profileCompleteProps.setProfileComplete(false);
    return false;
  }else{
    let profileComplete = false;
    if (KeycloakService.hasGroup(Object.keys(new AdminRoleNodes()))) {
      // admin
      profileComplete = (user.authId !== null)
        && (user.email !== null);
    } else {
      profileComplete = (user.authId !== null)
        && (user.firstName !== null)
        && (user.lastName !== null)
        && (user.email !== null)
        && (user.phone !== null)
        && (user.ssnDobSet);
    }
    profileCompleteProps.setProfileComplete(profileComplete);
    return profileComplete;
  }
};

export const mapToObject = (obj: {}, mapFunc: (key: string, value: any) => any):{} => {
  return Object.keys(obj).reduce(<P extends {}>(newObj: P, key: string) => {
      newObj[key as keyof P] = mapFunc(key, obj[key as keyof {}]);

      return newObj;
  }, {});
};

export const downloadCsv = async (csvContent: string, filename: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const createCsvContent = (rows: Object[]): string => {
  return rows.map((row, index) => index === 0 ? 
    Object.keys(row).join(',').concat('\n') + Object.values(row).join(',').concat('\n')
    : Object.values(row).join(',').concat('\n')
  ).join('');
}

export const idleTimout = (command: () => void, ms: number | string | undefined) => {
  let timer: NodeJS.Timeout;

  function resetTimer() {
    if(ms) {
      clearTimeout(timer);
      timer = setTimeout(command, +ms);
    }
  }
  resetTimer();

  return resetTimer;
}

export const escapeRegex = (chars: string) => {
  // Inspired by a highly upvoted answer here:
  // https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
  return chars.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const renameFile = (originalFile: File, newName: string): File => {
  return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
  });
}