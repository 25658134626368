import { Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default function BackLink({title}: {title: string}) {       
    const navigate = useNavigate();
    const location = useLocation();
  
  function handleClick(e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>): void {
    const paths = location.pathname.split('/');
    // Ugly Bug Fix: with a refresh keycloak does an extra navigate that makes react-router's navigate(-1) not go back
    // and cause the navigation type to no longer be a refresh, so if Uuid @ index navigate
    if(paths.length > 3 && paths[3].split('-').length === 5) {
      navigate(`/${paths[1]}/${paths[2]}/${paths[3]}`);
    } else {
      navigate(-1);
    }
  }

    return (
      <Link
        tabIndex={0} // Need this to get it into the tab order since it doesn't have an href
        color='inherit'
        onClick={ handleClick }
        onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
          // Keyboard accessibility - "click" link when pressing enter or space
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleClick(e);
          }
        }}
        underline='hover'
        sx={{
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
          fontSize: '0.875rem',
          mt: 0.5,
          mb: 1.25
        }}
      >
        <ChevronLeftIcon fontSize='small' sx={{ ml: '-0.25rem', mr: 0.25 }} />
        <Typography variant='body2'>{title}</Typography>
      </Link>
    );
  };