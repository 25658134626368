import { 
    Button, 
    Box,
    Link, 
    List, 
    ListItem, 
    ListItemAvatar, 
    ListItemText, 
    Menu, 
    MenuItem, 
    TextField, 
    ToggleButton, 
    ToggleButtonGroup, 
    Typography
} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useContext, useEffect, useState } from "react";
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { visuallyHidden } from '@mui/utils';
import { iUserServiceHistoryCommentDTO, iUserServiceHistoryDTO } from "../utilities/APIInterfaces";
import { ServiceHistoryType, ServiceHistoryTypeLabel } from '../utilities/FormEnums';
import ConfirmationDialog from './ConfirmationDialog';
import * as yup from 'yup';
import { useFormik } from "formik";
import userService from '../services/UserService';
import { UserContext } from '../contexts/UserContext';
import KeycloakService from '../services/KeycloakService';
import useFormikFirstErrorFocus from '../utilities/useFormikFirstErrorFocus';

const adminServiceHistorySX = {
    toggleButtonGroup: {
      flexWrap: 'wrap',
      gap: 1,
      mb: 3.75,
      '& .MuiToggleButtonGroup-firstButton, & .MuiToggleButtonGroup-middleButton, & .MuiToggleButtonGroup-lastButton': {
        backgroundColor: 'grey.300',
        border: '1px solid transparent',
        borderRadius: '100vw',
        color: 'grey.800',
        fontSize: '0.7rem',
        fontWeight: 'bold',
        ml: 0
      }
    },
    toggleButton: {
      px: 1.5,
      pt: '1px',
      pb: 0,
      textTransform: 'none',
      transition: 'all 0.25s',
      '&.Mui-disabled': {
        backgroundColor: 'grey.100',
        border: '1px solid transparent',
        color: 'grey.400'
      },
      '&.Mui-disabled.Mui-selected': {
        backgroundColor: 'rgb(0 109 63 / 0.25)', // Still want to see which buttons are selected, even if disabled
        color: 'InceptiaGreen.contrastText'
      },
      '&:not(.Mui-disabled):hover, &:not(.Mui-disabled).Mui-selected, &:not(.Mui-disabled).Mui-selected:hover': {
        backgroundColor: 'InceptiaGreen.main',
        color: 'InceptiaGreen.contrastText'
      },
      '& .MuiTouchRipple-child': {
        color: 'InceptiaGreen.main'
      },
      '&.Mui-selected .MuiTouchRipple-child': {
        color: 'white'
      }
    }
};

type ServiceHistoryFilterType = 'All' | ServiceHistoryType;

type ServiceHistoryProps = {
  userId: string;
  setIsServiceHistoryLoaded: (v: boolean) => void;
  submissionUuid: string;
  serviceHistory: iUserServiceHistoryDTO[];
}
export default function ServiceHistory({ userId, setIsServiceHistoryLoaded, submissionUuid, serviceHistory }: ServiceHistoryProps) {
    const userContext = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedFilters, setSelectedFilters] = useState<ServiceHistoryFilterType[]>(['All']);

    const iconMap: any = {
      [ServiceHistoryType.EMAIL]: <EmailOutlinedIcon />,
      [ServiceHistoryType.TEXT]: <StayCurrentPortraitIcon />,
      [ServiceHistoryType.FORM]: <NotificationsNoneOutlinedIcon />,
      [ServiceHistoryType.COMMENT]: <ChatOutlinedIcon />
    };

    type CommentDialogOptionsType = {
      isOpen: boolean;
      isWorking: boolean;
      commentId?: number;
    };
    const [addCommentDialogOptions, setAddCommentDialogOptions] = useState({} as CommentDialogOptionsType);
    const [deleteCommentDialogOptions, setDeleteCommentDialogOptions] = useState({} as CommentDialogOptionsType);

    type ViewMoreDialogOptionsType = {
      isOpen: boolean;
      isWorking: boolean;
      icon?: JSX.Element;
      date?: string;
      title?: string;
      sh?: iUserServiceHistoryDTO;
    };
    const [viewMoreDialogOptions, setViewMoreDialogOptions] = useState({} as ViewMoreDialogOptionsType);
    const [isViewMoreDataLoaded, setIsViewMoreDataLoaded] = useState(false);

    // Users in the Keycloak "Admin" or "Manager" groups can delete any comment - not just their own.
    const canDeleteAnyComment = KeycloakService.hasGroup(['Admin']) || KeycloakService.hasGroup(['Manager']);

    const isActionMenuOpen = Boolean(anchorEl);

    const commentFormik = useFormik({
      initialValues: { commentDialog: '' },
      validationSchema: yup.object({
        commentDialog: yup.string().trim().required('Please enter a comment')
      }),
      onSubmit: values => {
        setAddCommentDialogOptions({ ...addCommentDialogOptions, isWorking: true });

        const comment: iUserServiceHistoryCommentDTO = {
          id: 0,
          auth_Id: userId,
          commenter_Id: userContext.user?.id || 0,
          submission_Forms_Id: 0,
          submission_Forms_Uuid: submissionUuid,
          created_On: new Date().toISOString(),
          comment: values.commentDialog
        };

        userService.CreateServiceHistoryComment(userId, comment, (v) => v)
          .finally(() => {
            setAddCommentDialogOptions({ ...addCommentDialogOptions, isOpen: false, isWorking: false });
            setIsServiceHistoryLoaded(false);
          });
      }
    });

    useFormikFirstErrorFocus({ formik: commentFormik });
  
    useEffect(() => {
      if (deleteCommentDialogOptions.isWorking) {
        userService.DeleteServiceHistoryComment(userId, deleteCommentDialogOptions.commentId!, (v) => v)
          .finally(() => {
            setDeleteCommentDialogOptions({ ...deleteCommentDialogOptions, isOpen: false, isWorking: false });
            setIsServiceHistoryLoaded(false);
          });

      }
    }, [deleteCommentDialogOptions.isWorking]);

    const handleActionButtonClick = (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleActionMenuClose = () => {
        setAnchorEl(null);
    };
    const handleActionMenuItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
      const action = (e.target as HTMLElement).closest('li')?.getAttribute('data-menu-action')?.toLowerCase();
  
      switch (action) {
        case 'add-comment':
          commentFormik.setValues({ commentDialog: '' });
          commentFormik.setTouched({ commentDialog: false });
          setAddCommentDialogOptions({ ...addCommentDialogOptions, isOpen: true, isWorking: false });
          break;
        case 'export-service-history':
          // !!! TODO: implement export service history
          break;
      }
  
      setAnchorEl(null);
    };

    const handleDeleteCommentClick = (e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => {
      const commentId = (e.target as HTMLElement).closest('a')?.getAttribute('data-comment-id');
      if (commentId) setDeleteCommentDialogOptions({ ...deleteCommentDialogOptions, commentId: Number(commentId), isOpen: true, isWorking: false });
    };

    const handleViewMoreClick = (e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => {
      // Need id AND type of the service to find the correct service history item in the list (ids can overlap since multiple tables are involved)
      const a = (e.target as HTMLElement).closest('a')!;
      const id = a.getAttribute('data-view-more-id');
      const type = a.getAttribute('data-view-more-type');
      const sh = serviceHistory.find(sh => sh.id === Number(id) && sh.type === Number(type));

      if (sh) {
        const icon = sh.type !== null ? iconMap[sh.type] ?? <QuestionMarkIcon /> : <QuestionMarkIcon />;
        let date = new Date(sh.action_Date ?? '').toLocaleString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        });
        date = date === 'Invalid Date' || isNaN(Date.parse(date)) ? 'Invalid Date' : date;

        let title = ''
        if (sh.type === ServiceHistoryType.COMMENT) {
          title = `${sh.commenter_Name} commented (${date})`;
        } else {
          title = Object.values(ServiceHistoryType).includes(sh.type) ? `${ServiceHistoryTypeLabel.get(sh.type)} (${date})` : `Unknown Service (${date})`;
        }

        setViewMoreDialogOptions({ icon, date, title, sh, isOpen: true, isWorking: false });

        if (sh.type === ServiceHistoryType.EMAIL) {
          setIsViewMoreDataLoaded(false);
          userService.GetUserServiceHistoryItem(userId, sh.id, sh.type, (v) => v)
            .then(data => {
              setViewMoreDialogOptions({ icon, date, title, sh: data, isOpen: true, isWorking: false });
            }).finally(() => {
              setIsViewMoreDataLoaded(true);  
            });
        }
      }
    };

    function handleFilterChange(e: React.MouseEvent<HTMLElement, MouseEvent>, value: ServiceHistoryFilterType[]): void { 
        // Check if default value is included in changes and dont include it
        let changes = value.filter(v => 
            selectedFilters.find(i => i === 'All') != null? v !== 'All' : true);
        // check if 'all' is selected without being default and remove everything else 
        changes = selectedFilters.find(i => i === 'All') == null
            && value.find(v => v === 'All')? 
                ['All'] : changes; 

        if (changes.length === 0) changes.push('All'); 
        
        setSelectedFilters(changes);
    }

    return (<>
      <Typography variant='h3' sx={{ display: 'flex', fontSize: '1.5rem', fontWeight: '500', mb: 1.25 }}>
        Service History
        <Button
          id="basic-button"
          aria-controls={isActionMenuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isActionMenuOpen ? 'true' : undefined}
          aria-label='More service history options'
          color='inherit'
          onClick={handleActionButtonClick}
          onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
            // Keyboard accessibility - open dropdown menu when pressing enter or space
            if (e.key === 'Enter' || e.key === ' ') handleActionButtonClick(e);
          }}
          sx={{ minWidth: 'revert', ml: 'auto', py: 0 }}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isActionMenuOpen}
          onClose={handleActionMenuClose}
          MenuListProps={{
            'aria-labelledby': 'more-button',
            dense: true,
            sx: {
              minWidth: '180px',
              textAlign: 'right'
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem data-menu-action='add-comment' onClick={handleActionMenuItemClick}>
            <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Add Comment</ListItemText>
          </MenuItem>
          <MenuItem data-menu-action='export-service-history' onClick={handleActionMenuItemClick}>
            <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Export Service History</ListItemText>
          </MenuItem>
        </Menu>
      </Typography>

      <ToggleButtonGroup
        aria-label='Form submissions filter'
        size='small'
        value={selectedFilters}
        onChange={handleFilterChange}
        sx={adminServiceHistorySX.toggleButtonGroup}
      >
        <ToggleButton
          aria-label='All filter button'
          value='All'
          sx={adminServiceHistorySX.toggleButton}
        >All</ToggleButton>

        <ToggleButton
          aria-label={`Email filter button`}
          value={ServiceHistoryType.EMAIL}
          sx={adminServiceHistorySX.toggleButton}
        >Emails</ToggleButton>

        <ToggleButton
          aria-label={`Text filter button`}
          value={ServiceHistoryType.TEXT}
          sx={adminServiceHistorySX.toggleButton}
        >Texts</ToggleButton>

        <ToggleButton
          aria-label={`Update filter button`}
          value={ServiceHistoryType.FORM}
          sx={adminServiceHistorySX.toggleButton}
        >Updates</ToggleButton>

        <ToggleButton
          aria-label={`Comment filter button`}
          value={ServiceHistoryType.COMMENT}
          sx={adminServiceHistorySX.toggleButton}
        >Comments</ToggleButton>
      </ToggleButtonGroup>

      <List
        sx={{
          // Want the "Service History" to never be taller than the 400px height in the Figma screens - set a max-height on the list.
          maxHeight: '245px',
          overflowY: 'auto',
          overscrollBehavior: 'contain',
          pt: 0,
          // Mac OS hiding scrollbars by default - overriding that (https://stackoverflow.com/questions/7492062/css-overflow-scroll-always-show-vertical-scroll-bar)
          // NOTE: This will affect Chrome on Windows, too.
          '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)'
          }
        }}
      >
        {serviceHistory.filter(sh => selectedFilters.includes('All') || selectedFilters.includes(sh.type!)).map((sh, index) => {
          const icon = sh.type !== null ? iconMap[sh.type] ?? <QuestionMarkIcon /> : <QuestionMarkIcon />;
          let date = new Date(sh.action_Date ?? '').toLocaleString('en-US', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          });
          date = date === 'Invalid Date' || isNaN(Date.parse(date)) ? 'Invalid Date' : date;

          let title = ''
          if (sh.type === ServiceHistoryType.COMMENT) {
            title = `${sh.commenter_Name} commented (${date})`;
          } else {
            title = Object.values(ServiceHistoryType).includes(sh.type) ? `${ServiceHistoryTypeLabel.get(sh.type)} (${date})` : `Unknown Service (${date})`;
          }

          return (
            <ListItem key={index} alignItems="flex-start" sx={{ p: 0, mb: 1.75 }}>
              <ListItemAvatar
                sx={{
                  mt: 0,
                  mr: 1.5,
                  minWidth: 'revert',
                  '& .MuiSvgIcon-root': {
                    width: '16px'
                  }
                }}
              >{icon}</ListItemAvatar>
              <ListItemText 
                sx={{ m: 0 }}
                primary={
                  <Typography sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.875rem',
                    fontWeight:'bold',
                    mb: 0.5
                  }}>
                    {title}
                    {/* Delete comment - ONLY if it's the user's comment */}
                    {sh.type === ServiceHistoryType.COMMENT && (canDeleteAnyComment || sh.commenter_Id === userContext.user?.id) && (
                      <Link
                        data-comment-id={sh.id}
                        tabIndex={0} // Need this to get it into the tab order since it doesn't have an href
                        title='Delete comment'
                        sx={{
                          color: 'error.main',
                          cursor: 'pointer',
                          ml: 'auto',
                          mr: 1,
                          px: 0.5
                        }}
                        onClick={handleDeleteCommentClick}
                        onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                          // Keyboard accessibility - "click" link when pressing enter or space
                          if (e.key === 'Enter' || e.key === ' ') handleDeleteCommentClick(e);
                        }}
                      >
                        <DeleteOutlinedIcon sx={{ width: '18px', height: 'auto' }} />
                      </Link>
                    )}
                  </Typography>
                }
                secondary={
                  <Typography sx={{ display: 'flex', flexWrap: 'wrap', fontSize: '0.875rem', fontStyle: 'italic', pr: 1 }}>
                    <Box
                      component='span'
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        // Italicized font was cutting off the top-right corner of the last letter because of the italics slant.
                        // A little padding on the right fixes it.
                        pr: 0.5
                      }}
                    >{sh.description}</Box>
                    {[ServiceHistoryType.EMAIL, ServiceHistoryType.TEXT, ServiceHistoryType.COMMENT].includes(sh.type) && (
                      <Link
                        data-view-more-id={sh.id}
                        data-view-more-type={sh.type}
                        tabIndex={0} // Need this to get it into the tab order since it doesn't have an href
                        sx={{
                          color: 'var(--link-color)',
                          cursor: 'pointer',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          ml: 'auto',
                          textDecoration: 'none',
                          '&:hover, &:focus': {
                            textDecoration: 'underline'
                          }
                        }}
                        onClick={handleViewMoreClick}
                        onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                          // Keyboard accessibility - "click" link when pressing enter or space
                          if (e.key === 'Enter' || e.key === ' ') handleViewMoreClick(e);
                        }}
                      >View More</Link>
                    )}
                  </Typography>
                }
              />

            </ListItem>
          );
        })}
      </List>

    {/* Add comment dialog ---------------------------------------------------- */}
    {addCommentDialogOptions.isOpen && <ConfirmationDialog
      ariaPrefix='add-comment-form'
      dialogTitle='Add Comment'
      singleButton={false}
      ctaButtonText='Save'
      ctaButtonWorkingText='Saving...'
      cancelButtonText='Cancel'
      open={addCommentDialogOptions.isOpen}
      setOpenDialog={() => setAddCommentDialogOptions({ ...addCommentDialogOptions, isOpen: false })}
      isWorking={addCommentDialogOptions.isWorking}
      setIsWorking={() => {
        commentFormik.handleSubmit();
      }}
      width={528}
      dialogSX={{
        content: { pb: 0 }
      }}
    >
      <Typography
        variant='body2'
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          // Figma color (#b8b8b8) causes an accessibility contrast error - need to darken it
          color: 'text.secondary',
          mb: 3,
        }}
      >
        <LockOutlinedIcon sx={{ fontSize: '1rem', mb: 0.5, mr: 0.5 }} />Comments are internal and not visible to students/contributors
      </Typography>

      {/* Input field needs a label, but we don't want to see it */}
      <Typography component='label' htmlFor='commentDialog' sx={visuallyHidden}>Comment</Typography>
      <TextField
        id='commentDialog'
        variant='outlined'
        size='small'
        autoFocus
        fullWidth

        // 2024-03-18 (Still true as of 2024-06-18): Setting a TextField to be multiline will create a second textarea
        // that has an aria-hidden="true" attribute.
        // WAVE will complain about the hidden textarea having a "Missing form label" - false positive according to this:
        // https://github.com/mui/material-ui/issues/35580
        multiline

        minRows={7}
        maxRows={9}
        placeholder='Write your comment'
        spellCheck='false'
        value={commentFormik.values.commentDialog}
        onChange={commentFormik.handleChange}
        onBlur={commentFormik.handleBlur}
        onFocus={e => e.target.select()}
        error={commentFormik.touched.commentDialog && Boolean(commentFormik.errors.commentDialog)}
        helperText={commentFormik.touched.commentDialog && commentFormik.errors.commentDialog}
        sx={{
          // Want the input's border to be red even when it has focus (without this the focused border color is the Inceptia Green)
          '& .Mui-error.Mui-focused fieldset': {
            borderColor: '#d32f2f'
          }
        }}
      />
    </ConfirmationDialog>}

    {/* Delete comment dialog ------------------------------------------------- */}
    {deleteCommentDialogOptions.isOpen && <ConfirmationDialog
      ariaPrefix='delete-comment'
      dialogTitle='Delete comment?'
      singleButton={false}
      ctaButtonText='Remove'
      ctaButtonWorkingText='Removing...'
      cancelButtonText='Cancel'
      open={deleteCommentDialogOptions.isOpen}
      setOpenDialog={() => setDeleteCommentDialogOptions({ ...deleteCommentDialogOptions, isOpen: false })}
      isWorking={deleteCommentDialogOptions.isWorking}
      setIsWorking={() => setDeleteCommentDialogOptions({ ...deleteCommentDialogOptions, isWorking: true })}
      width={528}
    >
      <Typography variant='body2' sx={{ mt: 2.5 }}>This will permanently remove this comment from service history.</Typography>
    </ConfirmationDialog>}

    {/* View more dialog ------------------------------------------------------ */}
    {viewMoreDialogOptions.isOpen && <ConfirmationDialog
      ariaPrefix='view-more'
      dialogTitle='View Details'
      singleButton
      ctaButtonText='OK'
      ctaButtonWorkingText='Closing...'
      cancelButtonText='Cancel'
      open={viewMoreDialogOptions.isOpen}
      setOpenDialog={() => setViewMoreDialogOptions({ ...viewMoreDialogOptions, isOpen: false })}
      isWorking={viewMoreDialogOptions.isWorking}
      setIsWorking={() => setViewMoreDialogOptions({ ...viewMoreDialogOptions, isWorking: true })}
      width={528}
      dialogSX={{
        actions: { display: 'none' }
      }}
    >
      <Typography
        component='div'
        variant='body2'
        sx={{
          // Figma color (#b8b8b8) causes an accessibility contrast error - need to darken it
          color: '#757575',
          display: 'flex',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Box sx={{
          display: 'flex',
          mr: 1.5,
          '& .MuiSvgIcon-root': {
            width: '16px',
            height: 'auto'
          }
        }}>{viewMoreDialogOptions.icon}</Box>
        {viewMoreDialogOptions.title}
      </Typography>

      {/* Don't want the dialog to be taller than 420px to match the Figma design */}
      <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
        {/* Email has Subject/Message labels */}
        {viewMoreDialogOptions.sh?.type === ServiceHistoryType.EMAIL && (<>
          <Typography variant='body2' sx={{ mb: 2, textWrap: 'balance' }}>
            <Box component='span' sx={{ fontWeight: 'bold', mr: 1 }}>Subject:</Box>
            {viewMoreDialogOptions.sh.description}
          </Typography>
          { ! isViewMoreDataLoaded && (
            <Typography variant='body2' sx={{ mb: 0 }}>
              <Box component='span' sx={{ fontWeight: 'bold', mr: 1 }}>Message:</Box>
              Loading...
            </Typography>
          )}
          {isViewMoreDataLoaded && (
            <Typography variant='body2' sx={{ mb: 0 }}>
              <Box component='span' sx={{ fontWeight: 'bold', mr: 1 }}>Message:</Box>
              {viewMoreDialogOptions.sh.body}
            </Typography>
          )}
        </>)}

        {/* Texts/Comments just display the content - no labels */}
        {viewMoreDialogOptions.sh?.type !== ServiceHistoryType.EMAIL && (
          <Typography variant='body2' sx={{ mb: 0, textWrap: 'balance' }}>
            {viewMoreDialogOptions.sh?.description}
          </Typography>
        )}
      </Box>
   </ConfirmationDialog>}

  </>)
}