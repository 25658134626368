import { Skeleton, TableCell, TableRow } from '@mui/material';

export type TableLoaderProps = {
  colsNum: number;
  rowsNum: number;
  animation?: 'pulse' | 'wave' | false;
  rowHeight?: number | null;
};
const TableRowsLoader = ({
  colsNum,
  rowsNum,
  animation = 'pulse',
  rowHeight = null
}: TableLoaderProps) => {
  return (
    <>
      {[...Array(rowsNum)].map((row, index) => (
        <TableRow key={index}>
          {[...Array(colsNum)].map((col, index) => (
            <TableCell
              key={index}
              sx={{
                ...(rowHeight && { height: rowHeight })
              }}
            >
              <Skeleton animation={animation} variant='text' />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableRowsLoader;