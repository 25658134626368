import { useTheme, Box, BoxProps } from '@mui/material';
import { sideBarDrawerWidth } from './SideBarDrawer';

interface MainContentContainerProps extends BoxProps {
  widthUnderMd: boolean;
  openSideBarDrawer: boolean;
}

const MainContentContainer = (props: MainContentContainerProps) => {
  const { widthUnderMd, openSideBarDrawer, ...other } = props;
  const theme = useTheme();
  return (
    <Box
      {...other}
      sx={{
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(openSideBarDrawer && {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          ml: `${sideBarDrawerWidth}px`,
          ...(widthUnderMd && {
            width: '100%'
          })
        })
      }}
    >
      {other.children}
    </Box>
  );
};

export default MainContentContainer;
