import { Card, TextField } from "@mui/material";
import { useState } from "react";

type DateRangeDialogProps = {
    selectedDateRange: string[];
    setSelectedDateRange: (value: string[]) => void;
}

export function DateRange({selectedDateRange, setSelectedDateRange}: DateRangeDialogProps) {
    const [selectedStartDate, setSelectedStartDate] = useState(selectedDateRange[0] ?? '');
    const [selectedEndDate, setSelectedEndDate] = useState(selectedDateRange[1] ?? '');

    function handleStartDateChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setSelectedStartDate(event.target.value);
        setSelectedDateRange([event.target.value, selectedEndDate]);
        selectedEndDate === '' && setSelectedEndDate(new Date().toISOString().split('T')[0]);
    }

    function handleEndDateChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setSelectedEndDate(event.target.value);
        setSelectedDateRange([selectedStartDate, event.target.value]);
    }

    return ( 
        <>
            <TextField
                variant='outlined'
                id="startDate"
                label="Submission Start Date"
                type="date"
                onChange={handleStartDateChange}
                value={selectedStartDate}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  // Don't allow future dates
                  ...({ max: new Date().toISOString().split('T')[0] }),
                  sx: {
                    py: '11px'
                  }
                }}
                sx={{pr: 1, mt: 1.25, width: '50%'}}
            />
            <TextField
                variant='outlined'
                id="endDate"
                label="Submission End Date"
                type="date"
                onChange={handleEndDateChange}
                value={selectedEndDate}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  // Don't allow future dates
                  ...({ min: selectedStartDate ?? '' }),
                  sx: {
                    py: '11px'
                  }
                }}
                sx={{mt: 1.25, width: '50%'}}
            />
        </>
    );
}