import { useEffect, useState } from "react";
import KeycloakService from '../services/KeycloakService';
import { AdminRoleNodes } from "../utilities/APIInterfaces";

export function RoleControlled(groups: AdminRoleNodes) {
    const [role, setRole] = useState("");

    useEffect(() => {
      // Assumes that we wont be using multiple groups
      setRole(KeycloakService.getGroups().at(0)?? '');
    }, [groups]);

    return <>
              {{ Admin: groups.admin,
                  InstitutionAdmin: groups.institutionAdmin,
                  Institution: groups.institution,
                  SrOperations: groups.srOperations,
                  Operations: groups.operations,
                  Manager: groups.manager,
                }[role] ||  <></>}
           </>
}