import { Alert, Snackbar } from "@mui/material";
import React from "react";
import LoopIcon from '@mui/icons-material/Loop';

interface PageLoaderProps {
  message: string | undefined;
}

export const PageLoader: React.FC<PageLoaderProps> = (props) => {

  return (
    <div className="loader">
      <LoopIcon />
    </div>
  );
};