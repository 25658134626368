import { Container, Button, InputAdornment, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import SearchIcon from '@mui/icons-material/Search';

export function AdminSearchUsers({ searchQuery, setSearchQuery }: { searchQuery: string, setSearchQuery: React.Dispatch<React.SetStateAction<string>>})
{
  return(
    <Container sx={{py:3}}>
      <Stack component='form' direction='row' alignItems='center'>
        {/* Input field needs a label, but we don't want to see it */}
        <Typography component='label' htmlFor='search-forms' sx={visuallyHidden}>Search forms</Typography>
        <TextField
          size='small'
          type='search'
          id='search-forms'
          placeholder='Search...'
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
          }}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          autoFocus
        />

        <Button 
          variant='outlined'
          size='small'
          color='inherit'
          sx={{
            borderRadius: '100px',
            borderColor: 'grey.300', // Copied from Figma
            fontSize: '1rem',
            minWidth: '100px',
            textTransform: 'none',
            ml: 3
          }}
          onClick={() => alert('clicked Filters')}
        >
          Filters
        </Button>

        <Button
          size='small'
          color='inherit'
          disabled={!searchQuery}
          onClick={() => setSearchQuery('')}
          sx={{
            fontSize: '1rem',
            textTransform: 'none',
            ml: 1
          }}
        >
          Reset
        </Button>
      </Stack>
    </Container>
  );
}

type AdminUsersTableProps = {
  searchQuery: string
};
export default function AdminUsersTable(
    { searchQuery }: AdminUsersTableProps) {
      
  return (
    <Container sx={{py:3}}>
      TODO {searchQuery}
    </Container>
  );
}