import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as yup from 'yup';
import { useFormik } from "formik";
import { AdminFormDataContext } from '../../contexts/AdminFormDataContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { formatDate, isJsonString } from '../../utilities/HelperFunctions';
import { Priority, PriorityNumber, VerifierType, VerifierTypeNumber, VisibilityType, VisibilityTypeNumber } from '../../utilities/FormEnums';
import adminFormsService, { AdminFormSaveType } from '../../services/AdminFormsService';
import CustomTextField from '../CustomTextField';
import { AdminFormCrudProps } from '../../pages/AdminFormCrud';
import useFormikFirstErrorFocus from '../../utilities/useFormikFirstErrorFocus';

export type BasicInfoDataType = {
  uuid: string;
  name: string;
  description?: string | null;
  start_Date: string;
  due_Date?: string | null;
  json?: string | null;
  verifier_Type_Id: number;
  visibility_Type_Id: number;
  priority_Id: number;
};

const FormCrudBasicInfo = ({ editAccess = true, ...props }: AdminFormCrudProps) => {
  const { formData, isAdminFormDataLoaded, setIsAdminFormDataLoaded } = useContext(AdminFormDataContext);
  const { organization, isOrganizationDataLoaded } = useContext(OrganizationContext);

  const [isSaving, setIsSaving] = useState(false);
  const [copyToClipboardIcon, setCopyToClipboardIcon] = useState(<ContentCopyIcon />);
  const [copyToClipboardTitle, setCopyToClipboardTitle] = useState('Copy to clipboard');

  const uploadFileInputRef = useRef<HTMLInputElement | null>(null);
  const privateFormURLInputRef = useRef<HTMLInputElement | null>(null);

  const basicInfoSX = {
    textField: {
      mb: 3
    },
    radioGroup: {
      display: 'inline-flex',
      mb: 5.25
    },
    formControlLabel: {
      mr: 0,
      '& .MuiFormControlLabel-label': {
        fontSize: '0.875rem'
      },
      // Don't want the label greyed-out when disabled - just the radio button
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: 'var(--text-color)'
      }
    }
  };

  const buildPrivateFormURL = () => {
    const purl = organization?.purl ?? 'ORG_PURL_MISSING';
    const formUuid = formData?.uuid ? formData.uuid :
      props.action === 'add' ? props.basicInfoData?.uuid : 'FORM_UUID_MISSING';

    return window.location.origin + `?purl=${purl}&formUuid=${formUuid}`;
  };

  const updateJsonFileQuestions = (json: string) => {

    // Only want to do this work if the JSON contains questions of type "file"
    // So, check for any whitespaced variation of the string: '"type": "file"'
    if ( ! json.match(/"\s*type\s*"\s*:\s*"\s*file\s*"/gm)) return json;

    // Attempt to return the JSON in the same "format" as it came in (prettified or not).
    let isPretty = false;
    if (json.includes('\n') || json.match(/^\s+/gm)) isPretty = true; // Line breaks or indenting qualify as "pretty"

    const parsedJSON = JSON.parse(json);

    const recursiveSearch = (obj: any) => {
      if (typeof obj === 'object') {
        for (const key in obj) {
          if (key === 'elements') {
            obj[key].forEach((element: { type: string, storeDataAsText?: boolean }) => {
              // If the property doesn't exist, it will be added - if it does, it will be overwritten with false
              if (element.type === 'file') element.storeDataAsText = false;
              recursiveSearch(element);
            });
          } else {
            recursiveSearch(obj[key]);
          }
        }
      }
    };
    recursiveSearch(parsedJSON);

    return isPretty? JSON.stringify(parsedJSON, null, 2) : JSON.stringify(parsedJSON);
  };

  const basicInfoFormik = useFormik({
    initialValues: {
      action: props.action,
      name: props.action === 'edit' ? 'Loading...' : '',
      description: props.action === 'edit' ? 'Loading...' : '',
      aidYear: 'Loading...',
      startDate: '',
      dueDate: '',
      uploadJSONFile: '',
      json: '',
      isValidJSONFile: true,
      visibility: 'Public',
      privateFormURL: '',
      verification: 'Inceptia',
      priority: 'Required'
    },
    validationSchema: yup.object({
      action: yup.string(),
      name: yup.string().trim().required('Form name is required'),
      description: yup.string(),
      aidYear: yup.string().trim(),
      startDate: yup.date().required('Start date is required'),
      dueDate: yup.date(),
      uploadJSONFile: yup.string().when(() => 
        props.action === 'add' ?
          yup.string().trim().required('SurveyJS JSON file is required when adding a form')
        :
          yup.string().trim()
      ),
      json: yup.string().when(() => 
        props.action === 'add' ?
          yup.string().required('A valid JSON file is required when adding a form')
        :
          yup.string()
      ),
      isValidJSONFile: yup.boolean().required().oneOf([true], 'SurveyJS JSON file is required'),
      visibility: yup.string(),
      privateFormURL: yup.string(),
      verification: yup.string(),
      priority: yup.string()
    }),
    onSubmit: values => {

      // Possibly add "storeDataAsText" property to "file"-type questions in the JSON
      if (values.json) values.json = updateJsonFileQuestions(values.json);

      if (props.action === 'add') {
        if (props && props.setIsBasicInfoDataValid) props.setIsBasicInfoDataValid(true);
        if (props && props.setBasicInfoData) {
          props.setBasicInfoData({
            uuid: props.action === 'add' ? props.basicInfoData?.uuid || '' : formData?.uuid || '',
            name: values.name,
            description: values.description,
            start_Date: values.startDate,
            due_Date: values.dueDate,
            json: values.json ? values.json : null,
            verifier_Type_Id: VerifierTypeNumber.get(values.verification) || VerifierType.INCEPTIA,
            visibility_Type_Id: VisibilityTypeNumber.get(values.visibility) || VisibilityType.PUBLIC,
            priority_Id: PriorityNumber.get(values.priority) || Priority.REQUIRED
          });
        }
      } else {
        setIsSaving(true);

        const saveForm: AdminFormSaveType = {
          uuid: formData?.uuid || '',
          name: values.name,
          description: values.description,
          start_Date: values.startDate,
          due_Date: values.dueDate,
          json: values.json ? values.json : null,
          verifier_Type_Id: VerifierTypeNumber.get(values.verification) || VerifierType.INCEPTIA,
          visibility_Type_Id: VisibilityTypeNumber.get(values.visibility) || VisibilityType.PUBLIC,
          priority_Id: PriorityNumber.get(values.priority) || Priority.REQUIRED,
          version_Number: formData?.version_Number
        };

        adminFormsService.UpdateForm(saveForm, (v) => v)
          .finally(() => {
            setIsAdminFormDataLoaded(false);
          });
      }
    }
  });

  useFormikFirstErrorFocus({ formik: basicInfoFormik });

  useEffect(() => {
    if (isAdminFormDataLoaded && isOrganizationDataLoaded) {
      basicInfoFormik.setValues({
        action: props.action,
        name: formData?.name || '',
        description: formData?.description || '',
        aidYear: props.action === 'edit' ? formData?.aid_Year || '' : '2024-2025',
        startDate: formatDate(formData?.start_Date || '', false, true),
        dueDate: formatDate(formData?.due_Date || '', false, true),
        uploadJSONFile: '',
        json: '',
        isValidJSONFile: true,
        visibility: formData?.visibility || 'Public',
        privateFormURL: buildPrivateFormURL(),
        verification: formData?.verification || 'Inceptia',
        priority: formData?.priority || 'Required'
      });
    }

  }, [isAdminFormDataLoaded, isOrganizationDataLoaded]);

  useEffect(() => {
    if (isAdminFormDataLoaded) setIsSaving(false);
  }, [isAdminFormDataLoaded]);

  useEffect(() => {
    if (props.action === 'add' && props.createFormButtonClicked) {
      saveChanges();
    }
  }, [props.createFormButtonClicked]);

  const readFileAsTextAsync: (inputFile: File) => Promise<string> = (inputFile: File) => {
    const reader = new FileReader();
  
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
  
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsText(inputFile);
    });
  };

  const saveChanges = async () => {
    // Don't reset the createFormButtonClicked flag here, FormCrudBilling.tsx (last component on the page) will do that
    // if (props.setCreateFormButtonClicked) props.setCreateFormButtonClicked(false);

    basicInfoFormik.submitForm();
  };

  const validateJSONFile = async (filename: string) => {
    basicInfoFormik.setFieldValue('isValidJSONFile', true);
    basicInfoFormik.setFieldValue('uploadJSONFile', filename);

    let isInValid = false;

    if (filename) {
      const file = uploadFileInputRef.current!.files![0];
      if (file.type !== 'application/json') {
        isInValid = true;
      } else {
        try {
          const json = await readFileAsTextAsync(file);
          if (isJsonString(json)) {
            basicInfoFormik.setFieldValue('json', json);
          } else {
            isInValid = true;
          }
        } catch (e) {
          isInValid = true;
        }
      }

      if (isInValid) basicInfoFormik.setFieldValue('isValidJSONFile', false);
    }
  };

  const handleSaveChangesClick = () => {
    saveChanges();
  }

  const handleUploadFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await validateJSONFile('' + e.target.value.split('\\').pop());
  };

  const handleClearSelectedFileClick = () => {
    basicInfoFormik.setFieldValue('isValidJSONFile', true);
    basicInfoFormik.setFieldValue('uploadJSONFile', '');
    basicInfoFormik.setFieldValue('json', '');
    uploadFileInputRef.current!.value = '';
  };

  const handleCopyToClipboardClick = () => {
    navigator.clipboard.writeText(privateFormURLInputRef.current?.value || '');
    setCopyToClipboardIcon(<CheckIcon />);
    setCopyToClipboardTitle('Copied!');

    // Reset the icon and title after 3 seconds
    setTimeout(() => {
      setCopyToClipboardIcon(<ContentCopyIcon />);
      setCopyToClipboardTitle('Copy to clipboard');
    }, 3000);
  };

  return (<>
    <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 4.5 }}>Basic Information</Typography>

    <CustomTextField
      disabled={!editAccess}
      autoFocus
      id='name'
      labelText='Form Name'
      placeholder="Enter the form's name"
      value={basicInfoFormik.values.name}
      onBlur={basicInfoFormik.handleBlur}
      onChange={basicInfoFormik.handleChange}
      onFocus={e => e.target.select()}
      error={basicInfoFormik.touched.name && Boolean(basicInfoFormik.errors.name)}
      helperText={basicInfoFormik.touched.name && basicInfoFormik.errors.name}
      sx={basicInfoSX.textField}
    />

    <CustomTextField
      disabled={!editAccess}
      shrinkLabel={!editAccess}
      id='description'
      labelText='Description (optional)'
      placeholder={editAccess ? "Enter the form's description" : ''}
      value={basicInfoFormik.values.description}
      onBlur={basicInfoFormik.handleBlur}
      onChange={basicInfoFormik.handleChange}
      onFocus={e => e.target.select()}
      sx={basicInfoSX.textField}
    />

    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 3.5 }} sx={{ mb: 3 }}>

      <CustomTextField
        id='institution'
        labelText='Institution'
        disabled
        value={props.action === 'add' ?
          (isOrganizationDataLoaded ? organization?.name || '' : 'Loading...') :
          (isAdminFormDataLoaded ? formData?.institution || '' : 'Loading...')}
      />

      <CustomTextField
        id='aidYear'
        labelText='Aid Year'
        disabled
        value={basicInfoFormik.values.aidYear}
      />

    </Stack>

    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 3.5 }} sx={{ mb: 3 }}>

      <CustomTextField
        disabled={!editAccess}
        id='startDate'
        labelText='Start Date'
        shrinkLabel
        type='date'
        value={basicInfoFormik.values.startDate}
        onBlur={basicInfoFormik.handleBlur}
        onChange={basicInfoFormik.handleChange}
        error={basicInfoFormik.touched.startDate && Boolean(basicInfoFormik.errors.startDate)}
        helperText={basicInfoFormik.touched.startDate && basicInfoFormik.errors.startDate}
      />

      <CustomTextField
        disabled={!editAccess}
        id='dueDate'
        labelText='Due Date (optional)'
        shrinkLabel
        type='date'
        value={basicInfoFormik.values.dueDate}
        onBlur={basicInfoFormik.handleBlur}
        onChange={basicInfoFormik.handleChange}
        error={basicInfoFormik.touched.dueDate && Boolean(basicInfoFormik.errors.dueDate)}
        helperText={basicInfoFormik.touched.dueDate && basicInfoFormik.errors.dueDate}
      />

    </Stack>

    <Typography variant='body1' id='upload-json-file' sx={{ fontWeight: 500, mb: 1.25 }}>Content</Typography>

    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      columnGap: 1.5,
      rowGap: 1,
      mb: 3.75
    }}>
      <Button
        disabled={!editAccess}
        id='json' // Need a formik "focus" target for when the user selects an invalid JSON file (useFormikFirstErrorFocus.ts)
        component='label'
        htmlFor='uploadJSONFile'
        tabIndex={-1} // Only want the associated TextField to receive focus while tabbing
        variant='contained'
        startIcon={<UploadFileIcon />}
        sx={{
          bgcolor: 'InceptiaGreen.main',
          fontSize: '1rem',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'InceptiaGreen.dark',
          },
          // WAVE accessibility contrast error fix
          '& legend .notranslate': {
            color: '#fff'
          }
        }}
      >
      Upload SurveyJS JSON File
      <TextField
        inputRef={uploadFileInputRef}
        id='uploadJSONFile'
        required={props.action === 'add'}
        type='file'
        inputProps={{ accept: '.json' }}
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: 1
        }}
        onChange={handleUploadFileInputChange}
      />
    </Button>
    <Typography
      component='div'
      variant='body1'
      sx={{
        color: 'InceptiaGreen.main',
        display: 'inline-flex',
        alignItems: 'center',
        wordBreak: 'break-all'
      }}
    >
      {basicInfoFormik.values.uploadJSONFile}
      {basicInfoFormik.values.uploadJSONFile && (
        <IconButton
          aria-label='clear selected file'
          color='inherit'
          size='small'
          sx={{ ml: 1 }}
          title='Clear selected file'
          onClick={handleClearSelectedFileClick}
        ><CloseIcon sx={{ fontSize: '1rem' }} /></IconButton>
      )}
    </Typography>
    {basicInfoFormik.touched.uploadJSONFile && basicInfoFormik.errors.uploadJSONFile && (
      <Typography
        component='p'
        variant='caption'
        sx={{
          color: 'error.main',
          fontWeight: 500,
          width: '100%'
        }}
      >{basicInfoFormik.errors.uploadJSONFile}</Typography>
    )}
    { ! basicInfoFormik.values.isValidJSONFile && (
      <Typography
        component='p'
        variant='caption'
        sx={{
          color: 'error.main',
          fontWeight: 500,
          width: '100%'
        }}
      >File upload failed. Make sure it is in correct JSON format.</Typography>
    )}
    </Box>

    <Typography variant='body1' id='form-visibility-group-label' sx={{ fontWeight: 500 }}>Form Visibility</Typography>
    <RadioGroup
      aria-labelledby='form-visibility-group-label'
      name='visibility'
      value={basicInfoFormik.values.visibility}
      onBlur={basicInfoFormik.handleBlur}
      onChange={basicInfoFormik.handleChange}
      sx={{ ...basicInfoSX.radioGroup,
        ...(basicInfoFormik.values.visibility === 'Private' && { mb: 1 })
      }}
    >
      <FormControlLabel disabled={!editAccess} value='Public' control={<Radio />} label='Public' sx={basicInfoSX.formControlLabel} />
      <FormControlLabel disabled={!editAccess} value='Private' control={<Radio />} label='Private' sx={basicInfoSX.formControlLabel} />
    </RadioGroup>

    {basicInfoFormik.values.visibility === 'Private' && (
      <Box><CustomTextField
        inputRef={privateFormURLInputRef}
        id='privateFormURL'
        labelText='Private Form URL'
        inputProps={{
          sx: {
            fontSize: '0.875rem',
            py: '13px'
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment position='end'>
            <IconButton
              aria-label='Copy to clipboard'
              title={copyToClipboardTitle}
              onClick={handleCopyToClipboardClick}
            >
              {copyToClipboardIcon}
            </IconButton>
          </InputAdornment>
        }}
        value={basicInfoFormik.values.privateFormURL}
        onFocus={e => e.target.select()}
        sx={{
          maxWidth: '385px', // Figma width
          ml: 3.75,
          mb: 6.25
        }}
      /></Box>
    )}

    <Typography variant='body1' id='verification-type-group-label' sx={{ fontWeight: 500 }}>Verification Type</Typography>
    <RadioGroup
      aria-labelledby='verification-type-group-label'
      name='verification'
      value={basicInfoFormik.values.verification}
      onBlur={basicInfoFormik.handleBlur}
      onChange={basicInfoFormik.handleChange}
      sx={basicInfoSX.radioGroup}
    >
      <FormControlLabel disabled={!editAccess} value='Inceptia' control={<Radio />} label='Inceptia' sx={basicInfoSX.formControlLabel} />
      <FormControlLabel disabled={!editAccess} value='Institution' control={<Radio />} label='Institution' sx={basicInfoSX.formControlLabel} />
    </RadioGroup>

    <Typography variant='body1' id='form-priority-group-label' sx={{ fontWeight: 500 }}>Form Priority</Typography>
    <RadioGroup
      aria-labelledby='form-priority-group-label'
      name='priority'
      value={basicInfoFormik.values.priority}
      onBlur={basicInfoFormik.handleBlur}
      onChange={basicInfoFormik.handleChange}
      sx={basicInfoSX.radioGroup}
    >
      <FormControlLabel disabled={!editAccess} value='Required' control={<Radio />} label='Required' sx={basicInfoSX.formControlLabel} />
      <FormControlLabel disabled={!editAccess} value='Optional' control={<Radio />} label='Optional' sx={basicInfoSX.formControlLabel} />
    </RadioGroup>

    {props.action === 'edit' && (
      <div><Button
        variant='contained' 
        disabled={isSaving || ! editAccess}
        sx={{
          fontSize: '1rem',
          textTransform: 'none',
          bgcolor: 'InceptiaGreen.main',
          '&:hover': {
            backgroundColor: 'InceptiaGreen.dark',
          },
          ...(isSaving && { cursor: 'wait' })
        }}
        onClick={handleSaveChangesClick}>
        {isSaving ? (
          <>
            <CircularProgress
              color='inherit'
              size={20}
              sx={{ mr: 1.5 }}
            />
            Saving...
          </>
        ) : 'Save Changes'}</Button></div>
    )}

  </>);
};

export default FormCrudBasicInfo;