import { useContext, useEffect, useState } from 'react';
import { Box, Container, Link, List, ListItem, ListItemText, Skeleton, Typography } from '@mui/material';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import { useSnackbar } from 'notistack';
import { UserContext } from '../contexts/UserContext';
import { cardSX } from '../utilities/CSS';
import userService from '../services/UserService';
import ConfirmationDialog from './ConfirmationDialog';

// API endpoint - /Salesforce/User/{authId}/Contacts
interface iContactsData {
  id: string;
  org: string;
  name: string;
  email: string;
  phone: string;
  avatar: string;
}

type ContactsLoaderProps = {
  rowsNum: number;
  animation?: 'pulse' | 'wave' | false;
};
const ContactsLoader = ({ rowsNum, animation = 'pulse' }: ContactsLoaderProps) => {
  return (
    <List sx={{ py: 0 }}>
      {[...Array(rowsNum)].map((row, index) => (
        <ListItem
          key={index}
          sx={{
            gap: 1,
            p: 0,
            '& + .MuiListItem-root': {
              mt: 2.75
            }
          }}
        >
          <ListItemText sx={{ my: 0 }}>
            <Skeleton animation={animation} variant='text' width={125} />
            <Skeleton animation={animation} variant='text' sx={{ fontSize: '0.75rem' }} width={200} />
            <Skeleton animation={animation} variant='text' sx={{ fontSize: '0.75rem' }} width={175} />
          </ListItemText>
          <Skeleton animation={animation} variant='rounded' width={200} height={52} />
        </ListItem>
      ))}
    </List>
  );
};

const EmptyState = () => {
  return (
    <>
      <Box sx={cardSX.emptyStateBox}>
        <PersonOffOutlinedIcon sx={{ color: '#eee', fontSize: '5.55rem', mb: 2 }} />
        <Typography variant='body2'>No contacts yet.</Typography>
      </Box>

      <Link
        href='/dashboard/settings'
        underline='hover'
        variant='body1'
        sx={{
          fontWeight: 500,
          mx: 'auto',
          width: 'fit-content'
        }}
      >Manage Contacts</Link>
    </>
  );
};

type ContactsListProps = {
  contactsData: iContactsData[];
  handleContactUsClick: (e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => void;
};
const ContactsList = ({ contactsData, handleContactUsClick }: ContactsListProps) => {
  return (
    <List sx={{
      overflowY: 'auto',
      py: 0,
      pr: 2
    }}>
      {contactsData.map(contact => (
        <ListItem
          key={contact.id}
          sx={{
            alignItems: 'flex-start',
            gap: 1,
            p: 0,
            '& + .MuiListItem-root': {
              mt: 2.75
            }
          }}
        >
          <ListItemText sx={{ my: 0 }}>
            <Typography variant='body1' sx={{ fontWeight: 500, textWrap: 'balance' }}>{contact.org}</Typography>

            {contact.org.toUpperCase() === 'INCEPTIA' && (
              <Link
                tabIndex={0} // Need this to get it into the tab order since it doesn't have an href
                underline='hover'
                sx={{
                  cursor: 'pointer',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                }}
                onClick={handleContactUsClick}
                onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                  // Keyboard accessibility - "click" link when pressing enter or space
                  if (e.key === 'Enter' || e.key === ' ') handleContactUsClick(e);
                }}
              >Contact Us</Link>

              // 2024-08-05: Don't show Inceptia's phone number - multiple phone numbers based on product
            )}

            {contact.org.toUpperCase() !== 'INCEPTIA' && (<>
              <Typography variant='body2' sx={{ fontSize: '0.75rem' }}>
                Email:
                {contact.email ?
                  <Link href={`mailto:${contact.email}`} underline='hover' sx={{ ml: 0.5, wordBreak: 'break-word' }}>{contact.email}</Link> :
                  <Box component='span' sx={{ ml: 0.5 }}>N/A</Box>
                }
              </Typography>

              <Typography variant='body2'sx={{ fontSize: '0.75rem' }}>
                Phone:
                {/* Figma screen had color (#9e9e9e) for text - but that causes an accessibility contrast error */}
                <Typography variant='body2' component={'span'} sx={{ fontSize: '0.75rem', ml: 0.5 }}>{contact.phone ? contact.phone : 'N/A'}</Typography>
              </Typography>
            </>)}

          </ListItemText>
          <Box
            component='img'
            src={`${process.env.PUBLIC_URL}/logos/${contact.avatar}`}
            alt={`${contact.name} Logo`}

            // Hacky, but it works
            onLoad={e => (e.target as HTMLImageElement).classList.remove('logo-file-not-found')}
            onError={e => (e.target as HTMLImageElement).classList.add('logo-file-not-found')}
            sx={{
              maxWidth: '200px',
              '&.logo-file-not-found': { display: 'none' }
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default function Contacts() {
  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);
  const currentAuthId = userContext.user?.authId?.toString();

  const [contactsData, setContactsData] = useState<iContactsData[]>([]);
  const [isContactsDataLoaded, setIsContactsDataLoaded] = useState(false);

  type ContactUsDialogOptionsType = {
    isOpen: boolean;
    isWorking: boolean;
  };
  const [contactUsDialogOptions, setContactUsDialogOptions] = useState({} as ContactUsDialogOptionsType);
  const [isUConnInList, setIsUConnInList] = useState(false);
  const [inceptiaContact, setInceptiaContact] = useState<iContactsData | undefined>();
  const accountHelpEmail = 'accountsupport@inceptia.org';

  useEffect(() => {
    const GetYourContactsDataAsync = async (authId: string | undefined, setIsContactsDataLoaded: (isLoaded: boolean) => void) => {
      if (authId) {
        await userService.GetYourContacts(authId, (v) => v).then(result => {
          if (result) {
            setContactsData(result as iContactsData[]);
          } else {
            setContactsData([]);
          }
        }).catch(error => {
          enqueueSnackbar(error.toString());
          console.log('GetYourContacts 1', error);
        }).finally(() => {
          setIsContactsDataLoaded(true);
        })
      }
    };

    GetYourContactsDataAsync(currentAuthId, setIsContactsDataLoaded);
  }, [currentAuthId, enqueueSnackbar]);

  useEffect(() => {
    if (isContactsDataLoaded) {
      setIsUConnInList(contactsData.some(contact => contact.org === 'University of Connecticut'));
      setInceptiaContact(contactsData.find(contact => contact.org.toUpperCase() === 'INCEPTIA'));
    }
  }, [isContactsDataLoaded, contactsData]);

  const handleContactUsClick = (e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => {
    setContactUsDialogOptions({ ...contactUsDialogOptions, isOpen: true, isWorking: false });
  };

  return (
    <Container sx={cardSX.container}>

      <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 3.25 }}>Your Contacts{ ! isContactsDataLoaded ? ' (Loading...)' : ''}</Typography>

      {/* Loading... */}
      { ! isContactsDataLoaded && <ContactsLoader rowsNum={contactsData.length || 3} />}

      {/* !!! Not supposed to happen - empty state */}
      {isContactsDataLoaded && contactsData.length === 0 && <EmptyState />}

      {isContactsDataLoaded && contactsData.length > 0 && <ContactsList contactsData={contactsData} handleContactUsClick={handleContactUsClick} />}

      {/* Contact Us dialog --------------------------------------------------- */}
      {contactUsDialogOptions.isOpen && <ConfirmationDialog
        ariaPrefix='contact-us'
        dialogTitle='Contact Inceptia'
        singleButton
        ctaButtonText='OK'
        ctaButtonWorkingText='Closing...'
        cancelButtonText='Cancel'
        open={contactUsDialogOptions.isOpen}
        setOpenDialog={() => setContactUsDialogOptions({ ...contactUsDialogOptions, isOpen: false })}
        isWorking={contactUsDialogOptions.isWorking}
        setIsWorking={() => setContactUsDialogOptions({ ...contactUsDialogOptions, isWorking: true })}
        width={528}
        dialogSX={{
          title: {
            borderBottom: '1px solid #eaeaea',
          },
          content: { mt: 5 },
          // Hide the action buttons
          actions: { display: 'none' }
        }}
      >
        {/* Account Help ---------------------------------- */}
        <Typography
          variant='body1'
          sx={{
            fontWeight: 500,
            mb: 1.5
          }}
        >Account Help</Typography>
        <Typography variant='body2' sx={{ mb: 0.5 }}>Issues with login, password reset, etc.</Typography>
        <Typography variant='body2' sx={{ mb: 3.75 }}>
          Email:
          <Link
            href={`mailto:${accountHelpEmail}`}
            underline='hover'
            sx={{
              ml: 0.5,
              fontWeight: 'bold',
              wordBreak: 'break-word'
            }}
          >{accountHelpEmail}</Link>
        </Typography>

        {/* Form Help ------------------------------------- */}
        <Typography
          variant='body1'
          sx={{
            fontWeight: 500,
            mb: 1.5
          }}
        >Form Help</Typography>
        <Typography variant='body2' sx={{ mb: 0.5 }}>Issues or questions related to a form</Typography>
        <Typography variant='body2' sx={{ mb: isUConnInList ? 12.25 : 0.5 }}>
          Email:
          <Link
            // "Form Help" email will be different for students associated with UCONN
            href={`mailto:${isUConnInList ? accountHelpEmail : inceptiaContact!.email}`}
            underline='hover'
            sx={{
              ml: 0.5,
              fontWeight: 'bold',
              wordBreak: 'break-word'
            }}
          >{isUConnInList ? accountHelpEmail : inceptiaContact!.email}</Link>
        </Typography>

        {/* Don't display Inceptia's "Form Help" phone number for students associated with UCONN */}
        { ! isUConnInList && (
          <Typography variant='body2' sx={{ mb: 12.125 }}>Phone: {inceptiaContact!.phone}</Typography>
        )}

      </ConfirmationDialog>}

    </Container>
  );
}
