import { createContext } from 'react';

interface ILoadingContext {
    isLoading: boolean;
    setLoading: (value: boolean) => void;
}

export const defaultState = {
    isLoading: false,
    setLoading: () => console.log("not implemented")
};

const LoadingContext = createContext<ILoadingContext>(defaultState);

export default LoadingContext;