import { useContext, useState, useEffect, useCallback } from 'react';
import { Alert, Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, List, ListItemButton, ListItemText, ListSubheader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { CurrentFormContext } from './ToDoList';
import { inceptiaGreenAlphaColors } from '../utilities/CSS';
import { UserContext } from '../contexts/UserContext';
import { useSnackbar } from 'notistack';
import userService from '../services/UserService';

interface iInstitutionData {
  readonly [index: string]: number | string | boolean;
  id: number;
  name: string;
  school_Code: string;

  // Following fields are calculated
  total_Forms: number;
  total_Selected_Forms: number;
  selected: boolean;
}

let institutionDataDefaults: iInstitutionData = {
  id: 0,
  name: "",
  school_Code: "",
  total_Forms: 0,
  total_Selected_Forms: 0,
  selected: false
};

interface iFormData {
  readonly [index: string]: number | string | boolean;
  id: number;
  name: string;
  description: string;
  organization_Id: number;
  organization: string;
  form_Code: string;
  due_Date: string;
  selected: boolean;
}

// Fields to include in search
const searchParam = ['name', 'description', 'organization', 'due_Date'];

// All three of these need to be kept in sync for the layout to work responsively (left column widths at different screen sizes)
const institutionsWidth = { xs: '150px', sm: '245px', md: '360px' };
const institutionsWidthNeg = { xs: '-150px', sm: '-245px', md: '-360px' };
const institutionsWidthCalc = { xs: 'calc(100% + 150px)', sm: 'calc(100% + 245px)', md: 'calc(100% + 360px)'};

type ModalHeaderProps = {
  handleClose: () => void;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
};
const ModalHeader = ({ handleClose, searchQuery, setSearchQuery } : ModalHeaderProps) => {
  const theme = useTheme();
  return (
    <>
      <Typography variant='h4' sx={{fontSize: 32, fontWeight: 700, mb: 4.5}} id='form-modal-title'>Add Form(s)</Typography>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: theme.spacing(3),
          right: theme.spacing(3)
        }}
      >
        <CloseIcon />
      </IconButton>
      <Paper sx={{
        borderRadius: 2,
        boxShadow: '0px 6px 10px 4px rgba(173, 183, 192, 0.20), 0px 2px 4px 0px rgba(173, 183, 192, 0.10)', // Box shadow copied directly from Figma
        mb: 1.5,
        p: 3
      }}>
        {/* Input field needs a label, but we don't want to see it */}
        <Typography component='label' htmlFor='search-forms' sx={visuallyHidden}>Search forms</Typography>
        <TextField
          type='search'
          id='search-forms'
          placeholder='Search forms'
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
          }}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          autoFocus
          size='small'
        />
      </Paper>
    </>
  );
};

const EmptyTableRow = () => {
  return (
    <TableRow>
      <TableCell colSpan={4}>
        <Alert icon={false} sx={{
          color: 'InceptiaGreen.main',
          backgroundColor: inceptiaGreenAlphaColors.selected
        }}>
          <Typography variant='h5'>No forms found matching selected institutions or search criteria.</Typography>
        </Alert>
      </TableCell>
    </TableRow>
  );
};

type FormTableProps = {
  selectedInstitutions: string[];
  searchQuery: string;
  formData: iFormData[];
  setFormData: React.Dispatch<React.SetStateAction<iFormData[]>>;
  institutionData: iInstitutionData[];
  setInstitutionData: React.Dispatch<React.SetStateAction<iInstitutionData[]>>;
  dialogContentHeight: number;
  addFormButtonCount: number;
  visibleFormCount: number;
}
const FormTable = ({
  selectedInstitutions,
  searchQuery,
  formData,
  setFormData,
  institutionData,
  setInstitutionData,
  dialogContentHeight,
  addFormButtonCount,
  visibleFormCount
}: FormTableProps) => {
  let rowsRendered = 0; // Keep track of how many rows get rendered in the table

  // Search and selected institutions determine if row is rendered or not
  const isTableRowVisible = (form: iFormData) => {
    // If there are selected institutions and the form doesn't match - don't render the row
    if (selectedInstitutions.length && ! selectedInstitutions.some(val => form['organization'].toString().toLowerCase() === val.toLowerCase())) {
      return false;
    }

    // If search field has a value and it's not found in the searchParam array for this form - don't render the row
    if (searchQuery.length && ! searchParam.some(key => form[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase()))) {
      return false;
    }

    return true;
  };

  const handleToggle = (value: string) => () => {
    if (formData !== null) {
      // Update each institutions form counter when a checkbox in the form list is checked/unchecked
      // Find the individual form whose checkbox was clicked and get the institution's name and if the checkbox is checked or unchecked
      const idx = formData.findIndex(e => (e.id + e.organization) === value);
      const checkedInstitution = formData[idx].organization;
      const isChecked = !formData[idx].selected;

      // Update the institutionData state with the updated form count
      const newInstitutions : iInstitutionData[] = institutionData.map(institution => {
        if (institution.name === checkedInstitution) {
          return {
            ...institution,
            // Guard against total_Selected_Forms being undefined initially
            total_Selected_Forms: isChecked ? (institution.total_Selected_Forms || 0) + 1 : institution.total_Selected_Forms - 1
          }
        } else {
          return institution;
        }
      })
      setInstitutionData(newInstitutions);
  
      const newFormDataArray : iFormData[] = formData.map (formItem => {
        if ((formItem.id + formItem.organization) === value) {
          return {
            ...formItem,
            selected: !formItem.selected
          }
        } else {
          return formItem;
        }
      })
      setFormData(newFormDataArray);
    }
  };

  const handleCheckAllToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (formData !== null) {
      const newFormDataArray: iFormData[] = formData.map(form => {
        // We only want to check/uncheck the form IF it's visible in the table
        if (isTableRowVisible(form)) {
          return {
            ...form,
            selected: checked
          };
        } else {
          return form;
        }
      })

      // Start by resetting all the institution's selected form counts to zero
      let newInstitutionData: iInstitutionData[] = institutionData;
      newInstitutionData.forEach(inst => {
        inst.total_Selected_Forms = 0;
      });
      // Loop through the NEW formData to get selected form counts for each institution
      newFormDataArray.forEach(form => {
        if (isTableRowVisible(form)) {
          const idx = newInstitutionData.findIndex(inst => inst.name === form.organization);
          if (checked) newInstitutionData[idx].total_Selected_Forms++;
        }
      });

      // Update state for forms and institutions
      setFormData(newFormDataArray);
      setInstitutionData(newInstitutionData);
    }
  }

  return (
    <TableContainer sx={{
      ...(dialogContentHeight > 0 && { height: dialogContentHeight })
    }}>
      <Table aria-label='List of forms table' stickyHeader>
        <TableHead>
          <TableRow
            sx={{
              borderColor: '#dde3ea', // Set the color on the row and then have cells inherit, so only one place to change if needed
              textTransform: 'uppercase'
            }}
          >
            <TableCell padding='checkbox' sx={{ borderColor: 'inherit', color: 'InceptiaGreen.main' }}>
              {/* Checkbox needs a label, but we don't want to see it */}
              <Typography component='label' htmlFor='select-all-forms' sx={visuallyHidden}>Toggle all</Typography>
              <Checkbox
                indeterminate={addFormButtonCount > 0 && addFormButtonCount < visibleFormCount}
                checked={visibleFormCount > 0 && addFormButtonCount === visibleFormCount}
                onChange={handleCheckAllToggle}
                inputProps={{
                  'aria-label': 'Toggle selection of forms in the table',
                  id: 'select-all-forms',
                  title: 'Check/uncheck all'
                }}
              />
            </TableCell>
            <TableCell sx={{ borderColor: 'inherit'}}>Form</TableCell>
            <TableCell sx={{ borderColor: 'inherit', textAlign: 'center' }}>Institution</TableCell>
            <TableCell sx={{ borderColor: 'inherit', textAlign: 'center', whiteSpace: 'nowrap' }}>Due Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        {formData.length === 0 && (
          <EmptyTableRow />
        )}

        {formData.map(form => {
          const labelId = `checkbox-list-label-${form.id + form.organization}`;

          // Search and selected institutions may cause a row to not be rendered
          if (isTableRowVisible(form)) {
            rowsRendered++;
          } else {
            return null;
          }

          return (
            <TableRow
              key={form.id + form.organization}
              hover
              role='checkbox'
              selected={form.selected}
              onClick={handleToggle(form.id + form.organization)}
              tabIndex={-1}
              sx={{
                borderColor: 'grey.100',
                cursor: 'pointer',
                verticalAlign: 'top',
                '&.MuiTableRow-hover:hover': {
                  backgroundColor: inceptiaGreenAlphaColors.selectedHover
                },
                '&.Mui-selected': {
                  backgroundColor: inceptiaGreenAlphaColors.selected
                },
                '&:last-child > td': { border: 0 }
              }}
            >
              <TableCell sx={{ borderColor: 'inherit', color: 'InceptiaGreen.main' }}>
                <Checkbox
                  edge='start'
                  checked={form.selected}
                  inputProps={{
                    'aria-label': 'Select form',
                    'id': labelId
                  }}
                />
              </TableCell>
              <TableCell sx={{ borderColor: 'inherit' }}>
                <Typography sx={{ fontWeight: 600 }}>{form.name}</Typography>
                <Typography sx={{
                  color: 'grey.600',
                }}>{form.description}</Typography>
              </TableCell>
              <TableCell sx={{ borderColor: 'inherit', textAlign: 'center' }}>{form.organization}</TableCell>
              <TableCell sx={{ borderColor: 'inherit', textAlign: 'center' }}>{form.due_Date}</TableCell>
            </TableRow>
          );
        })}

        {/* If there ARE forms in the array AND no rows got rendered, render the "empty table" component */}
        {(formData.length > 0 && rowsRendered === 0) && <EmptyTableRow />}

        </TableBody>
      </Table>
    </TableContainer>
  );
};

type InstitutionsNavProps = {
  institutionData: iInstitutionData[];
  handleInstitutionClick: (id: number) => void;
};
const InstitutionsNav = ({ institutionData, handleInstitutionClick }: InstitutionsNavProps) => {
  return (
    <List
      component='nav'
      aria-label='List of institutions'
      sx={{
        backgroundColor: 'grey.50',
        borderRight: '2px solid',
        borderColor: 'grey.100',
        pt: 0,

        // Want the list of institutions to be "separate" from the list of forms when it comes to scrolling
        // Without this adjustment, the institutions column scrolls in sync with the list of forms
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: institutionsWidth,
        overflowY: 'auto'
      }}
    >
      <ListSubheader
        component='div'
        sx={{
          backgroundColor: 'grey.50',
          // Compiles to #9e9e9e - which creates an accessibility contrast error - so commenting out
          // color: 'grey.500',
          fontWeight: 700,
          pt: 3,
          textTransform: 'uppercase'
        }}
      >Filter by institution</ListSubheader>

      {institutionData.map((inst) => (
        <ListItemButton
          key={inst.id}
          selected={inst.selected}
          onClick={() => handleInstitutionClick(inst.id)}
          sx={{
          justifyContent: 'space-between',
          pr: 1,
          '&:hover': {
            backgroundColor: inceptiaGreenAlphaColors.hover
          },
          '&.Mui-selected, &.Mui-selected:hover, &.Mui-focusVisible': {
            backgroundColor: inceptiaGreenAlphaColors.selected
          },
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: inceptiaGreenAlphaColors.selectedHover
          },
          '& .MuiTouchRipple-child': {
            color: 'InceptiaGreen.main'
          }
        }}>
          <ListItemText
            primary={inst.name}
            primaryTypographyProps={{
              display: { xs: 'none', sm: 'block' },
              fontWeight: 500,
              mr: 1
            }}
            secondary={inst.school_Code}
            secondaryTypographyProps={{
              // Compiles to #9e9e9e - which creates an accessibility contrast error - so commenting out
              // color: 'grey.500',
              display: { xs: 'none', sm: 'block' },
              fontSize: '0.75rem'
            }}
          />
          {inst.total_Selected_Forms > 0 &&
            <ListItemText
              primary={
                <Chip
                  label={inst.total_Selected_Forms}
                  variant='outlined'
                  size='small'
                  sx={{ borderColor: 'grey.800' }}
                />
              }
              sx={{ flexGrow: 0 }}
            />
          }
        </ListItemButton>
      ))}
    </List>
  );
};

type AddFormModalProps = {
  setIsToDoListDataLoaded: (isLoaded: boolean) => void;
}
export default function AddFormModal({ setIsToDoListDataLoaded }: AddFormModalProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);
  const currentUserAuthId = userContext.user?.authId?.toString();

  const { addFormIsOpen, setAddFormIsOpen } = useContext(CurrentFormContext);

  // Search input and form fields to include in search
  const [searchQuery, setSearchQuery] = useState('');

  const [institutionData, setInstitutionData] = useState<iInstitutionData[]>([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState<string[]>([]);
  const [formData, setFormData] = useState<iFormData[]>([]);

  const [visibleFormCount, setVisibleFormCount] = useState(0);
  const [addFormButtonCount, setAddFormButtonCount] = useState(0);
  const [totalFormCount, setTotalFormCount] = useState(0);

  // Don't want the dialog to appear until AFTER the Add Form Modal (AFM) data has been loaded
  // Need to calculate dialogContentHeight after the table has been populated with data
  const [isAFMDataLoaded, setIsAFMDataLoaded] = useState(false);

  const [dialogContentHeight, setDialogContentHeight] = useState(0);

  const [isSaving, setIsSaving] = useState(false);

  const SaveAFMFormDataAsync = async (
    submittedAFMFormData: {forms_Id: number}[],
    authId: string | undefined,
    setIsToDoListDataLoaded: (isLoaded: boolean) => void,
    setAddFormIsOpen: (isOpen: boolean) => void
  ) => {
  
    if (authId) {
      await userService.CreateUserForm(submittedAFMFormData, authId, (v) => v)
        .then()
        .catch(error => {
          enqueueSnackbar(error.toString());
          console.log("SaveAFMFormDataAsync 1", error);
        }).finally(() => {
          setIsToDoListDataLoaded(false);
          setAddFormIsOpen(false);
        });
    }
  };
  
  useEffect(() => {
    async function GetAFMOrganizationDataAsync(authId: string | undefined) {
      
  
          if (authId) {
            await userService.GetOrgsByUser(authId, (v) => v)
              .then(result => {
                if (result) {
                  let institutions: iInstitutionData[] = [];
                  result.forEach(orgDTO => {
                    let institution: iInstitutionData = {...institutionDataDefaults};
                    institution.id = orgDTO.id;
                    institution.name = orgDTO.name;
                    institution.school_Code = orgDTO.school_Code;
                    institutions.push(institution)
                  });
                
                setInstitutionData(institutions);
                }
              })
              .catch(error => {
                enqueueSnackbar(error.toString());
                console.log("GetAFMOrganizationDataAsync 1",error);
            });
           }
    }
  
    if (addFormIsOpen) GetAFMOrganizationDataAsync(currentUserAuthId);
  }, [addFormIsOpen, currentUserAuthId, enqueueSnackbar]);

  useEffect(() => {
    async function GetAFMFormDataAsync(authId: string | undefined) {
      if (authId) {
        await userService.GetUserForms(authId, (v) => v).then(result => {
          if (result) {
            (result as iFormData[]).forEach(form => {
              // Massage the due_Date so the values can be searched
              if (form.due_Date === null) {
                form.due_Date = 'N/A';
              } else {
                form.due_Date = new Date(form.due_Date).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: '2-digit'
                });
              }
              form.selected = false; // No forms are selected by default when the dialog is opened
            });
            setFormData(result as iFormData[]);
          }
        }).catch(error => {
          enqueueSnackbar(error.toString());
          console.log("GetAFMFormData 1", error);
        }).finally(() => {
          setIsAFMDataLoaded(true);
        });
      }
    }

    if (addFormIsOpen) GetAFMFormDataAsync(currentUserAuthId);
  }, [addFormIsOpen, currentUserAuthId, enqueueSnackbar]);

  useEffect(() => {
    setTotalFormCount(formData.length);
    if (formData.length) {
      setAddFormButtonCount(formData.filter(form => form.selected).length);
      institutionData.map(inst => inst.total_Forms = formData.filter(form => form.organization === inst.name).length);
    }
  }, [formData, institutionData]);

  useEffect(() => {
    setVisibleFormCount(totalFormCount);
  }, [totalFormCount]);

  // Need the callback to get the height "after" the dialog is visible and rendered on screen
  const dialogContentCallback = useCallback((node: HTMLElement) => {
     if (node !== null) {
      // Seems to be enough of a delay to get the height at the right time
      setTimeout(() => setDialogContentHeight(node.clientHeight), 50);
    }
  }, []);

  // EVENT HANDLERS ===========================================================
  const handleInstitutionClick = (id: number) => {
    const idx = institutionData.findIndex(e => e.id === id);
    const name = institutionData[idx].name;
    const isSelected = !institutionData[idx].selected;

    // If selecting an instituition, add it to the selectedInstitutions array, remove it if deselecting
    let newSelectedInstitutions: string[] = [];
    if (isSelected && !selectedInstitutions.includes(name)) {
      newSelectedInstitutions = [...selectedInstitutions];
      newSelectedInstitutions.push(name);
    }
    if (!isSelected && selectedInstitutions.includes(name)) {
      newSelectedInstitutions = [...selectedInstitutions].filter(item => item !== name);
    }
    setSelectedInstitutions(newSelectedInstitutions);

    const newInstitutions: iInstitutionData[] = institutionData.map(institution => {
      if (institution.name === name) {
        return {
          ...institution,
          selected: isSelected,
          total_Selected_Forms: (newSelectedInstitutions.length === 0 || isSelected) ? institution.total_Selected_Forms : 0
        }
      } else {
        return {
          ...institution,
          total_Selected_Forms: (newSelectedInstitutions.includes(institution.name)) ? institution.total_Selected_Forms : 0
        }
      }
    })
    setInstitutionData(newInstitutions);

    // IF there are selected institutions...
    if (newSelectedInstitutions.length) {
      let cnt = 0;
      newInstitutions.forEach(institution => {
        if (newSelectedInstitutions.includes(institution.name)) cnt += institution.total_Forms;
      });
      setVisibleFormCount(cnt);

      const newFormDataArray: iFormData[] = formData.map(formItem => {
        return {
          ...formItem,
          // If the form's institution is in the newSelectedInstitutions array, leave its selected status alone, otherwise set it to false
          selected: (newSelectedInstitutions.includes(formItem.organization!)) ? formItem.selected : false
        };
      });
      setFormData(newFormDataArray);
    } else {
      // No institutions selected, reset the visible formcount to the totalFormCount
      setVisibleFormCount(totalFormCount);
    }
  };

  const handleAddFormButtonClick = () => {
    let userForms: {forms_Id: number}[] = [];

    setIsSaving(true);

    formData.forEach(form => {
      if (form.selected && ! userForms.find(userForm => userForm.forms_Id === form.id)) {
        userForms.push({ forms_Id: form.id });
      }
    });

    SaveAFMFormDataAsync(userForms, currentUserAuthId, setIsToDoListDataLoaded, setAddFormIsOpen);
  };

  const handleClose = () => {
    setAddFormIsOpen(false);
  };

  return (
    <Dialog
      // Need this to get autofocus working in the search input? https://github.com/mui/material-ui/issues/33004#issuecomment-1455260156
      disableRestoreFocus
      open={isAFMDataLoaded && addFormIsOpen}
      onClose={handleClose}
      maxWidth='lg'
      fullScreen={fullScreen}
      PaperProps={{
        sx: { borderRadius: { xs: 0, md: 2 }}
      }}
      aria-labelledby='form-modal-title'
      aria-describedby='form-modal-content'
    >
      {/* Write the DialogTitle as a div instead of the default h2 to stop it from complaining about the ModalHeader h4 nested inside of it */}
      <DialogTitle component='div' sx={{ p: 3, ml: institutionsWidth }}>
        <ModalHeader handleClose={handleClose} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </DialogTitle>

      <DialogContent
        ref={dialogContentCallback}
        id='form-modal-content'
        sx={{
          ml: institutionsWidth,
          mb: 0,
          p: 0,
          minHeight: dialogContentHeight
        }}
      >
        <Grid container wrap='nowrap' sx={{
          ml: institutionsWidthNeg,
          width: institutionsWidthCalc,
          height: '100%'
        }}>
          <Grid item sx={{
            backgroundColor: 'grey.50',
            width: institutionsWidth,
            flexGrow: 0,
            flexShrink: 0
          }}>
            <InstitutionsNav institutionData={institutionData} handleInstitutionClick={handleInstitutionClick} />
          </Grid>
          <Grid item sx={{
            minWidth: { lg: 759, xl: 823 },
            width: '100%'
          }}>
            <Container>
              <FormTable
                selectedInstitutions={selectedInstitutions}
                searchQuery={searchQuery}
                formData={formData}
                setFormData={setFormData}
                institutionData={institutionData}
                setInstitutionData={setInstitutionData}
                dialogContentHeight={dialogContentHeight}
                addFormButtonCount={addFormButtonCount}
                visibleFormCount={visibleFormCount}
              />
            </Container>
          </Grid>
        </Grid> 
      </DialogContent>

      <DialogActions sx={{
        color: 'InceptiaGreen.main',
        borderTop: '2px solid',
        borderColor: 'grey.100',
        ml: institutionsWidth,
        p: 3
      }}>
        <Button
          variant='outlined'
          color='inherit'
          disabled={isSaving}
          sx={{
            fontSize: '1rem',
            flexGrow: '1',
            maxWidth: '315px',
            textTransform: 'none',
            '&:hover, &:focus': {
              backgroundColor: inceptiaGreenAlphaColors.hover
            }
          }}
          onClick={handleClose}
        >Cancel</Button>
        <Button 
          variant='contained' 
          disabled={isSaving || addFormButtonCount === 0}
          sx={{
            fontSize: '1rem',
            flexGrow: '1',
            maxWidth: '315px',
            textTransform: 'none',
            bgcolor: 'InceptiaGreen.main',
            '&:hover': {
              backgroundColor: 'InceptiaGreen.dark',
            },
          }}
          onClick={handleAddFormButtonClick}
        >
          {isSaving ? (
            <>
              <CircularProgress
                color='inherit'
                size={20}
                sx={{ mr: 1.5 }}
              />
              Adding...
            </>
          ) : `Add Form ${addFormButtonCount > 0 ? `(${addFormButtonCount})` : ''}`}</Button>
      </DialogActions>

    </Dialog>
  )
}
