import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import orgService from '../services/OrgService';
import KeycloakService from '../services/KeycloakService';

export interface iOrganization {
  id: number;
  name: string | null;
  city: string | null;
  state: string | null;
  opeid: string;
  school_Code: string;
  logoImage: string | null;
  purl: string | null;
}

export type OrganizationContextType = {
  organization: iOrganization | null | undefined;
  setOrganization: (Organization: iOrganization | null) => void;
  organizations: iOrganization[] | null | undefined;
  setOrganizations: (Organizations: iOrganization[] | null) => void;
  isOrganizationDataLoaded: boolean;
  setIsOrganizationDataLoaded: (value: boolean) => void;
};

export const OrganizationContext = createContext<OrganizationContextType>({
  organization: null,
  setOrganization: organization => console.warn('no user provider'),
  organizations: null,
  setOrganizations: organizations => console.warn('no user provider'),
  isOrganizationDataLoaded: false,
  setIsOrganizationDataLoaded: value => console.warn('no user provider')
});
export const useOrganization = () => useContext(OrganizationContext);

interface OrganizationContextProps {
        children?: React.ReactNode
}

export const OrganizationContextProvider: React.FC<OrganizationContextProps> = ({ children }) => {
  const [organization, setOrganization] = useState<iOrganization | null>(null);
  const [organizations, setOrganizations] = useState<iOrganization[] | null>([]);
  const [isOrganizationDataLoaded, setIsOrganizationDataLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function GetOrganizationDataAsync() {
      await orgService.GetAllowedOrganizations((v) => v)
        .then((result) => {
          if (result) {
            const orgsData = result as iOrganization[];
            const selectedOrg = orgsData.filter((org) => org.id === orgsData[0].id);
            setOrganization(selectedOrg[0]);
            setOrganizations(orgsData);
          } else {
            setOrganization(null);
            setOrganizations([]);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString());
          console.log("GetOrganizationDataAsync 1", error);
        }).finally(() => {
          setIsOrganizationDataLoaded(true);
        });
    }
  
    GetOrganizationDataAsync();
  }, [enqueueSnackbar]);

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        setOrganization,
        organizations,
        setOrganizations,
        isOrganizationDataLoaded,
        setIsOrganizationDataLoaded
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}

