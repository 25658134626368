import { useEffect } from "react";
import KeycloakService from '../services/KeycloakService';
import userService from "../services/UserService";

declare const window: Window &
   typeof globalThis & {
     pendo: any
   }

export function usePendo() {
    useEffect(() => {
        let accountId = '0';
        (async () => await userService.GetOrgsByUser(KeycloakService.getId(), (x)=>x))()
          .then((result) => accountId = result.length > 0? result[0].opeid : '0')
          .then(() => window.pendo.initialize({
            visitor: {
                id:              KeycloakService.getUsername(),   // Required if user is logged in
                // email:        // Recommended if using Pendo Feedback, or NPS Email
                // full_name:    // Recommended if using Pendo Feedback
                role:            KeycloakService.getGroups()[0] ?? 'Student'// Optional
        
                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },
        
            account: {
                id:              accountId // Required if using Pendo Feedback
                // name:         // Optional
                // is_paying:    // Recommended if using Pendo Feedback
                // monthly_value:// Recommended if using Pendo Feedback
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional
        
                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            }
        }));  
    }, [KeycloakService.isLoggedIn]);

    return window.pendo.isReady();
}