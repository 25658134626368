import { Container } from '@mui/material';
import DashboardAppBar from '../components/DashboardAppBar';
import DashboardLeftNav from '../components/DashboardLeftNav';
import DashboardCenterCommunications from '../components/DashboardCenterCommunications';
import MainContentContainer from '../components/MainContentContainer';
import SideBarDrawer, { SideBarDrawerProps } from '../components/SideBarDrawer';

export default function DashboardCommunications(props: SideBarDrawerProps) {
  const { widthUnderMd, openSideBarDrawer, setOpenSideBarDrawer } = props;
  return (
    <>
      <MainContentContainer widthUnderMd={widthUnderMd} openSideBarDrawer={openSideBarDrawer}>
        <DashboardAppBar {...props} />

        <Container sx={{ py: 4 }}>
          <DashboardCenterCommunications />
        </Container>
      </MainContentContainer>

      <SideBarDrawer
        variant={(widthUnderMd && openSideBarDrawer) ? 'temporary' : 'permanent'}
        open={openSideBarDrawer}
        onClose={() => setOpenSideBarDrawer(false)}
      >
        <DashboardLeftNav {...props} />
      </SideBarDrawer>
    </>
  );
}
