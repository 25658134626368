import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import SearchIcon from '@mui/icons-material/Search';
import AdminFormTable from './AdminFormTable';
import { AdminFormDataContextProvider, iAdminFormData } from '../contexts/AdminFormDataContext';
import { cardSX, pageHeaderSX } from '../utilities/CSS';
import { AdminRoleNodes } from '../utilities/APIInterfaces';
import { RoleRouteMapper } from './RoleRouteMapper';

type AdminSearchFormsAndStudentsProps = {
  autoFocus?: boolean;
  placeholderText: string;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
};
export function AdminSearchFormsAndStudents({ autoFocus=true, placeholderText, searchQuery, setSearchQuery }: AdminSearchFormsAndStudentsProps) {
  return(
    <Container sx={{ p: { xs: 2.5, sm: 2.5 } }}>
      <Stack component='form' direction='row' alignItems='center' gap={1.25}>
        {/* Input field needs a label, but we don't want to see it */}
        <Typography component='label' htmlFor='search-forms-and-students' sx={visuallyHidden}>Search forms</Typography>
        <TextField
          size='small'
          type='search'
          id='search-forms-and-students'
          placeholder={placeholderText}
          fullWidth
          InputProps={{
            sx: { pl: 1},
            startAdornment: <InputAdornment position='start'><SearchIcon sx={{ color: 'grey.800' }} /></InputAdornment>
          }}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            // Ignore ENTER key presses in the search field - don't want the form to submit and the page to reload
            if (e.key === 'Enter') e.preventDefault();
          }}
          autoFocus={autoFocus}
        />

        <Button
          color='inherit'
          disabled={!searchQuery}
          title='Clear search'
          variant='outlined'
          sx={{
            fontSize: '1rem',
            textTransform: 'none'
          }}
          onClick={() => setSearchQuery('')}
        >Clear</Button>
      </Stack>
    </Container>
  );
}

export default function AdminCenterHome() {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  return (
    <AdminFormDataContextProvider>
      <RoleRouteMapper 
        roles={Object.keys(new AdminRoleNodes())
          .filter((role) => ['admin','manager'].includes(role))}
      >
        <Button 
          variant="contained"
          onClick={() => navigate('/admin/formadd')}
          sx={{
            fontSize: '1rem',
            float:'right',
            textTransform: 'none',
            bgcolor: 'InceptiaGreen.main',
            '&:hover': {
              backgroundColor: 'InceptiaGreen.dark',
            },
          }}
        >+ Add Form</Button>
      </RoleRouteMapper>

      <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 4 }}>{process.env.REACT_APP_TITLE}</Typography>

      <Paper sx={{ ...cardSX.paper, mb: 3.75 }}>
        <AdminSearchFormsAndStudents
          placeholderText='Search forms'
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </Paper>

      <Paper sx={cardSX.paper}>
        <AdminFormTable searchQuery={searchQuery} />
      </Paper>

    </AdminFormDataContextProvider>
  );
}
