import { useState, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Container,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AdminFormDataContext, iAdminFormData } from '../contexts/AdminFormDataContext';
import { UserContext } from '../contexts/UserContext';
import adminFormsService from '../services/AdminFormsService';
import { inceptiaGreenAlphaColors } from '../utilities/CSS';
import ConfirmationDialog from './ConfirmationDialog';
import TableRowsLoader, { type TableLoaderProps } from './TableRowsLoader';
import { useSnackbar } from 'notistack';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { tableRowsPerPageOptions } from '../utilities/Misc';

type Order = 'asc' | 'desc';

type TableWrapperProps = {
  handleRequestSort: (e: React.MouseEvent<unknown>, property: keyof iAdminFormData) => void;
  sortField: string;
  sortDir: Order;
  children: React.ReactNode;
};
const TableWrapper = (props: TableWrapperProps) => {
  const { handleRequestSort, sortField, sortDir, children } = props;

  const thSX = {
    borderColor: '#d9d9d9',
    color: '#1e1e1e',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',

    // Keyboard accessibility tweak
    '& .Mui-focusVisible': {
      outline: '1px solid',
      outlineOffset: '5px'
    }
  };

  interface HeadCell {
    id: keyof iAdminFormData;
    label: string;
    align: 'left' | 'center' | 'right';
    sortable: boolean;
  };
  const headCells: readonly HeadCell[] = [
    { id: 'name', label: 'Form', align: 'left', sortable: true },
    { id: 'institution', label: 'Institution', align: 'left', sortable: true },
    { id: 'submissions', label: 'Submissions', align: 'center', sortable: true },
    { id: 'visibility', label: 'Visibility', align: 'left', sortable: true },
    { id: 'verification', label: 'Verification', align: 'left', sortable: true },
    { id: 'priority', label: 'Priority', align: 'left', sortable: true },
    { id: 'activeStatus', label: 'Status', align: 'left', sortable: true }
  ];

  const createSortHandler = (property: keyof iAdminFormData) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <TableContainer>
      <Table aria-label='admin forms table' size='small'>
        <TableHead>
          <TableRow sx={{ textTransform: 'uppercase' }}>
            {headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                // The sortDirection prop will write an aria-sort attribute to the <th> element
                {...(headCell.sortable ? { sortDirection: sortField === headCell.id ? sortDir : false } : {})}
                sx={thSX}
              >{headCell.sortable ? (
                <TableSortLabel
                  active={sortField === headCell.id}
                  direction={sortField === headCell.id ? sortDir : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {sortField === headCell.id ? (
                    <Box component='span' sx={visuallyHidden}>
                      {sortDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : headCell.label}
              </TableCell>
            ))}
            <TableCell sx={thSX}>
              {/* Fix for empty header cell accessibility error */}
              <Box sx={visuallyHidden}>Actions</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableLoader = ({ colsNum, rowsNum, animation = 'pulse' }: TableLoaderProps) => {
  return (
    <TableWrapper
      // !!! Feed all these props dummy values since it doesn't really matter for a loader
      sortField={'name'}
      sortDir={'asc'}
      handleRequestSort={() => {}}
    >
      <TableRowsLoader colsNum={colsNum} rowsNum={rowsNum} animation={animation} rowHeight={55} />
    </TableWrapper>
  );
};

export default function AdminFormTable({ searchQuery }: { searchQuery: string }) {
  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);
  const currentUserAuthId = userContext.user?.authId?.toString();

  const { formsData, isAdminFormDataLoaded, setIsAdminFormDataLoaded } = useContext(AdminFormDataContext);
  const { organization } = useContext(OrganizationContext);
  const navigate = useNavigate();

  const [sortDir, setSortDir] = useState<Order>('asc');
  const [sortField, setSortField] = useState<keyof iAdminFormData>('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteFormData, setDeleteFormData] = useState({} as { formUuid: string; formName: string; });

  const [isCannotDeleteDialogOpen, setIsCannotDeleteDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMoreMenuOpen = Boolean(anchorEl);

  const descendingComparator = (a: iAdminFormData, b: iAdminFormData, orderBy: keyof iAdminFormData) => {
    if ((b[orderBy] ?? '') < (a[orderBy] ?? '')) return -1;
    if ((b[orderBy] ?? '') > (a[orderBy] ?? '')) return 1;
    return 0;
  };
  const getComparator = (order: Order, orderBy: keyof iAdminFormData) => {
    return order === 'desc'
      ? (a: iAdminFormData, b: iAdminFormData) => descendingComparator(a, b, orderBy)
      : (a: iAdminFormData, b: iAdminFormData) => -descendingComparator(a, b, orderBy);
  };

  const filteredForms = useMemo(() => {
    // Fields to include in search
    const searchFields = ['name', 'institution', 'submissions', 'visibility', 'verification', 'priority', 'activeStatus'];

    if (!formsData) return [];

    return formsData!
      .slice().sort(getComparator(sortDir, sortField))
      .filter(item => {
        return searchFields.some(key => 
          item[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase()) && organization?.id === item['organization_Id']
        );
    });
  }, [formsData, searchQuery, sortDir, sortField]);

  useEffect(() => {
    const DeleteAdminFormAsync = async () => {
      if (currentUserAuthId) {
        await adminFormsService.DeleteAdminForm(deleteFormData.formUuid, currentUserAuthId, (v) => v)
          .then(result => {
            /*
              Possible that someone made a submission to the form since this page was loaded.
              If that's the case, the API call will return 405 and custom error message.
              If we find the 405 status in the result, we open the "Cannot Delete" dialog (deleteFormData object will have the form's name).
              Setting isAdminFormDataLoaded to false in the finally will reload the table and the selected form should have a submission count > 0.
            */
            const err = result as any;
            if (err.hasOwnProperty('status') && err.status === 405) setIsCannotDeleteDialogOpen(true);
          })
          .catch(error => {
            enqueueSnackbar(error.toString());
            console.log("DeleteAdminFormAsync 1", error);
          }).finally(() => {
            setIsAdminFormDataLoaded(false);
            setIsDeleting(false);
            setIsDeleteDialogOpen(false);
          });
      }
    };
  
    if (isDeleting) DeleteAdminFormAsync();
  }, [isDeleting, deleteFormData, enqueueSnackbar, currentUserAuthId, setIsAdminFormDataLoaded]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredForms!.length) : 0;

  // EVENT HANDLERS ===========================================================
  const handleMoreButtonClick = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMoreMenuItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    e.stopPropagation();

    // Get the form's ID and then form
    const formID = Number(anchorEl!.getAttribute('data-row-id'));
    const form = formsData?.find(form => form.id === formID);

    // Need to know which menu item was clicked (edit or delete)
    const action = (e.target as HTMLElement).closest('li')?.getAttribute('data-menu-action')?.toLowerCase();
    switch (action) {
      case 'edit':
        navigate('/admin/formedit/' + form?.uuid);
        break;
      case 'delete':
        const submissionsCount = Number(anchorEl?.getAttribute('data-row-submissions') || 0);
        const formName = form?.name || 'Did Not Find Form Name';
        const formUuid = form?.uuid || '';

        setDeleteFormData({ formUuid, formName });
        if (submissionsCount) {
          setIsCannotDeleteDialogOpen(true);
        } else {
          setIsDeleteDialogOpen(true);
        }
        break;
    }

    setAnchorEl(null);
  };

  const handleMoreMenuClose = (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
    // Don't want to trigger the table row click event handler when closing the more menu
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleRowClick = (uuid: string) => {
    navigate('/admin/form/' + uuid);
  };

  const handleRequestSort = (e: React.MouseEvent<unknown>, property: keyof iAdminFormData) => {
    // If the column clicked on is already sorted in asscending order, set the flag - which will result in a descending sort
    const isAsc = (sortField === property && sortDir === 'asc');

    setSortDir(isAsc ? 'desc' : 'asc');
    setSortField(property);
  };

  const handlePageChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const tdSX = {
    py: 1.125
  };

  return (
    <Container sx={{py:3}}>
      {/* Loading... */}
      { ! isAdminFormDataLoaded && <TableLoader colsNum={8} rowsNum={filteredForms.length || 10} />}

      {/* Loaded, but no forms returned from the API */}
      {isAdminFormDataLoaded && filteredForms.length === 0 && (
         <TableWrapper
          sortField={sortField as string}
          sortDir={sortDir}
          handleRequestSort={handleRequestSort}
         >
          <TableRow>
            <TableCell colSpan={8} sx={{ borderBottom: 'none' }}>
              <Typography
                variant='body1'
                sx={{
                  // WAVE accessibility contrast error if color matches Figma color of #b8b8b8, so darken it enough to pass (grey.600 = #757575)
                  color: 'grey.600',
                  fontWeight: 500,
                  py: 3.75,
                  textAlign: 'center'
                }}
              >No forms found...</Typography>
            </TableCell>
          </TableRow>
        </TableWrapper>
      )}

      {/* Loaded, with forms returned from the API */}
      {isAdminFormDataLoaded && filteredForms.length > 0 && (
        <TableWrapper
          sortField={sortField as string}
          sortDir={sortDir}
          handleRequestSort={handleRequestSort}
        >
          {(rowsPerPage > 0 ? filteredForms!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredForms!).map(row => (
            <TableRow
              hover
              key={row.id}
              role='button' // Keyboard accessibility
              tabIndex={0} // Keyboard accessibility
              sx={{
                cursor: 'pointer',
                '&:last-child td, &:last-child th': { border: 0 },
                '&:focus-visible': { backgroundColor: inceptiaGreenAlphaColors.hover }
              }}
              onClick={() => handleRowClick(row.uuid)}
              onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) => {
                // Keyboard accessibility - open form when pressing enter or space
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  handleRowClick(row.uuid);
                }
              }}
            >
              <TableCell sx={{ ...tdSX, color: 'InceptiaGreen.main', fontWeight:'500' }}>{row.name}</TableCell>
              <TableCell sx={tdSX}>{row.institution}</TableCell>
              <TableCell sx={{ ...tdSX, textAlign: 'center' }}>{row.submissions}</TableCell>
              <TableCell sx={tdSX}>{row.visibility}</TableCell>
              <TableCell sx={tdSX}>{row.verification}</TableCell>
              <TableCell sx={tdSX}>{row.priority}</TableCell>
              <TableCell sx={tdSX}>{row.activeStatus}</TableCell>
              <TableCell sx={{ ...tdSX, pr: 0 }}>
                <Button
                  id={`more-button-${row.id}`}
                  aria-controls={isMoreMenuOpen ? 'more-menu' : undefined}
                  aria-expanded={isMoreMenuOpen ? 'true' : undefined}
                  aria-haspopup='true'
                  aria-label='More form options'
                  color='inherit'
                  data-row-id={row.id}
                  data-row-submissions={row.submissions}
                  onClick={handleMoreButtonClick}
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                    // Keyboard accessibility - click More button when pressing enter or space
                    if (e.key === 'Enter' || e.key === ' ') handleMoreButtonClick(e);
                  }}
                  sx={{ minWidth: 'revert' }}
                >
                  <MoreHorizIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow sx={{ height: 53 * emptyRows }}>
              <TableCell colSpan={8} />
            </TableRow>
          )}
        </TableWrapper>
      )}

      <TablePagination
        component='div'
        count={filteredForms?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        rowsPerPageOptions={tableRowsPerPageOptions}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        sx={{
          '&, & .MuiTablePagination-input .MuiSvgIcon-root': { color: '#000' }
        }}
        slotProps={{
          actions: {
            previousButton: { 'aria-label': 'previous page' },
            nextButton: { 'aria-label': 'next page' }
          },
          // MUI generates WAVE "Missing form label" error, the options below fix it
          // https://stackoverflow.com/questions/66642553/tablepagination-mui-missing-form-label-accessibility-issue-in-wave-tool
          select: {
            id: 'rows-per-page-select',
            inputProps: {
              'aria-label': 'rows per page',
              'aria-labelledby': 'rows-per-page-select'
            }
          }
        }}
      />

      {/* Don't want the menu to be duplicated for each row in the table */}
      <Menu
        id='more-menu'
        anchorEl={anchorEl}
        open={isMoreMenuOpen}
        onClose={handleMoreMenuClose}
        MenuListProps={{
          'aria-labelledby': 'more-button',
          dense: true,
          sx: {
            minWidth: '180px',
            textAlign: 'right'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem data-menu-action='edit' onClick={handleMoreMenuItemClick}>
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Edit Form</ListItemText>
        </MenuItem>
        <MenuItem
          data-menu-action='delete'
          onClick={handleMoreMenuItemClick}
        >
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Delete Form</ListItemText>
        </MenuItem>
      </Menu>

      {/* Form CAN be deleted */}
      {isDeleteDialogOpen && <ConfirmationDialog
        ariaPrefix='delete-form'
        dialogTitle='Are you sure?'
        singleButton={false}
        ctaButtonText='Delete'
        ctaButtonWorkingText='Deleting...'
        cancelButtonText='Cancel'
        open={isDeleteDialogOpen}
        setOpenDialog={setIsDeleteDialogOpen}
        isWorking={isDeleting}
        setIsWorking={setIsDeleting}
      >
        <Typography variant='body2' sx={{ mb: 2 }}>This will delete <b>{deleteFormData.formName}</b> from the system.</Typography>
        <Alert severity='warning'>It will be permanently deleted and cannot be recovered.</Alert>
      </ConfirmationDialog>}

      {/* Form CANNOT be deleted */}
      {isCannotDeleteDialogOpen && <ConfirmationDialog
        ariaPrefix='cannot-delete-form'
        dialogTitle='Unable to Delete Form'
        singleButton={true}
        ctaButtonText='Close'
        ctaButtonWorkingText='Closing...'
        cancelButtonText=''
        open={isCannotDeleteDialogOpen}
        setOpenDialog={setIsCannotDeleteDialogOpen}
      >
        <Typography variant='body2' sx={{ mb: 2 }}>The <b>{deleteFormData.formName}</b> form cannot be deleted because it has submissions.</Typography>
       </ConfirmationDialog>}

    </Container>
  );
}