import { Container } from '@mui/material';
import DashboardAppBar from '../components/DashboardAppBar';
import DashboardLeftNav from '../components/DashboardLeftNav';
import DashboardCenterHome from '../components/DashboardCenterHome';
import MainContentContainer from '../components/MainContentContainer';
import SideBarDrawer, { SideBarDrawerProps } from '../components/SideBarDrawer';
import { ToDoListProps } from '../components/ToDoList';
import { RememberDeviceButtonProps } from '../components/RememberDeviceButton';
import { useEffect, useState } from 'react';
import RememberDevicePrompt from '../components/RememberDevicePrompt';

interface DashboardHomeProps{
  sideBarDrawerProps: SideBarDrawerProps;
  todoListProps: ToDoListProps;
  // rememberDeviceButtonProps:RememberDeviceButtonProps
}

export default function DashboardHome(props: DashboardHomeProps) {
  const [displayRememberDeviceMessage, setDisplayRememberDeviceMessage] = useState(false);
  let today= new Date().getDay();
  // useEffect(() => {
  //   setDisplayRememberDeviceMessage(!props.rememberDeviceButtonProps.deviceRemembered)
  // },[props.rememberDeviceButtonProps.deviceRemembered]);

  const removeOldStorage = () => {
    for(let x=0;x<=6;x++){
      if(x!==today){
        localStorage.removeItem('showRememberDevice.' + x);
      }
    }
  }

  async function closeRememberDeviceMessage() {
    //remove old storage
    removeOldStorage();
    //don't display it again for today
    localStorage.setItem('showRememberDevice.' + today, 'false');
    setDisplayRememberDeviceMessage(false);
  }
  
  const { widthUnderMd, openSideBarDrawer, setOpenSideBarDrawer } = props.sideBarDrawerProps;
  return (
    <>
      <MainContentContainer widthUnderMd={widthUnderMd} openSideBarDrawer={openSideBarDrawer}>
        <DashboardAppBar {...props.sideBarDrawerProps} />

        <Container sx={{ py: 4 }}>
          <DashboardCenterHome todoListProps = {props.todoListProps}/>
        </Container>
      </MainContentContainer>

      <SideBarDrawer
        variant={(widthUnderMd && openSideBarDrawer) ? 'temporary' : 'permanent'}
        open={openSideBarDrawer}
        onClose={() => setOpenSideBarDrawer(false)}
      >
        <DashboardLeftNav {...props.sideBarDrawerProps} />
      </SideBarDrawer>
      {/* {displayRememberDeviceMessage && <RememberDevicePrompt onClose={closeRememberDeviceMessage} isOpen={displayRememberDeviceMessage && localStorage.getItem('showRememberDevice') === null && localStorage.getItem('showRememberDevice.' + today) === null} rememberDeviceButtonProps={props.rememberDeviceButtonProps}></RememberDevicePrompt>}  */}
    </>
  );
}
