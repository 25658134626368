import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid/Grid';
import Paper from '@mui/material/Paper/Paper';
import ToDoList, { ToDoListProps } from './ToDoList';
import Communications from './Communications';
import Contacts from './Contacts';
import { cardSX, pageHeaderSX } from '../utilities/CSS';

interface DashboardCenterHomeProps {
  todoListProps: ToDoListProps;
}

export default function DashboardCenterHome(props: DashboardCenterHomeProps) {

  return (
    <>
      <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 4.5 }}>Home</Typography>

      <Grid container rowSpacing={{xs: 1.5, sm: 4.5}} columnSpacing={{sm: 3}}>

        <Grid item xs={12}>
          <Paper sx={cardSX.paperMinHeight}>
            <ToDoList {...props.todoListProps}/>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper sx={{ ...cardSX.paperMinHeight, 'height': '400px' }}>
            <Communications />
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper sx={{ ...cardSX.paperMinHeight, 'height': '400px' }}>
            <Contacts />
          </Paper>
        </Grid>

      </Grid>
    </>
  );
}
