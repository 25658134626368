import { useEffect, useState } from "react";
import { AdminRoleNodes } from "../utilities/APIInterfaces";
import { mapToObject } from "../utilities/HelperFunctions";
import { RoleControlled } from "./RoleControlled";

interface RoleProps {
    children: React.ReactNode,
    roles: string[]
}

export function RoleRouteMapper({children, roles}: RoleProps) {
    const [nodes, setNodes] = useState<AdminRoleNodes>();

    function mapToRole(roles:string[], element: React.ReactNode): AdminRoleNodes {
        return mapToObject(new AdminRoleNodes(), (key, _) => 
            roles.some((role) => role === key) && element);
    }

    useEffect(() => {
        setNodes(mapToRole(roles, children));
    }, [children]);

    return <RoleControlled {...nodes}/>
}