import { TextField, TextFieldProps } from '@mui/material';

type CustomTextFieldProps = {
  id: string;
  labelText: string;
  placeholder?: string;
  readOnly?: boolean;
  maxLength?: number;
  shrinkLabel?: boolean;
} & TextFieldProps;

const CustomTextField = ({ id, labelText, placeholder, readOnly, maxLength, shrinkLabel, ...props }: CustomTextFieldProps) => {
  return (
    <TextField
      id={id}
      fullWidth
      label={labelText}
      size='small'
      inputProps={{
        id: id,
        maxLength: maxLength,
        placeholder: placeholder,
        readOnly: readOnly,
        sx: {
          py: '13px',
          ...(props.type === 'date' && { textTransform: 'uppercase' }) // Want the 'mm/dd/yyyy' placeholder to be uppercase to match Figma
        }
      }}
      InputLabelProps={{
        ...(shrinkLabel && { shrink: true }),
        sx: {
          // Want the floating label vertically centered within the input since we increased the vertical padding on the input tag (py: '13px' above)
          '&[data-shrink="false"]': {
            transform: 'translate(14px, 13px)'
          }
        }
      }}
      sx={{
        '&:has([readonly]) ': {
          '& .MuiInputBase-root': {
            backgroundColor: '#f6f6f6',
          }
        }
      }}
      {...props}
    />
  );
};

export default CustomTextField;