import { useTheme, Drawer, DrawerProps } from '@mui/material';

export const sideBarDrawerWidth = 300;
export type SideBarDrawerProps = {
  widthUnderMd: boolean;
  openSideBarDrawer: boolean;
  setOpenSideBarDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

const SideBarDrawer = (props: DrawerProps) => {
  const theme = useTheme();
  return (
    <Drawer
      {...props}
      PaperProps={{
        sx: {
          width: sideBarDrawerWidth,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: `-${sideBarDrawerWidth}px`,
          ...(props.open && {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0
          })
        }
      }}
    >
      {props.children}
    </Drawer>
  );
};

export default SideBarDrawer;
