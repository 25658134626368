import { useContext, useState } from 'react';
import { Container, Skeleton, Typography } from '@mui/material';
import { BackToFormsLink } from '../AdminCenterForm';
import { AdminFormDataContext } from '../../contexts/AdminFormDataContext';
import { pageHeaderSX } from '../../utilities/CSS';
import CustomTabs, { CustomTabPanel } from '../CustomTabs';
import FormCrudBasicInfo from './FormCrudBasicInfo';
import FormCrudCommunications from './FormCrudCommunications';
import FormCrudTags from './FormCrudTags';
import FormCrudBilling from './FormCrudBilling';
import KeycloakService from '../../services/KeycloakService';

const FormEdit = () => {
  const { formData, isAdminFormDataLoaded } = useContext(AdminFormDataContext);
  const [selectedTab, setSelectedTab] = useState(0);
  
  // Users in the Keycloak "Admin" or "Manager" groups can edit a form, all other users will have readonly access when editing a form
  const editAccess = KeycloakService.hasGroup(['Admin']) || KeycloakService.hasGroup(['Manager']);

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setSelectedTab(newTab);
  };

  const containerSX = {
    p: {
      xs: 2.25,
      sm: 4.5
    }
  };

  return (<>
    {/* Loading... */}
    { ! isAdminFormDataLoaded && (<>
      <Skeleton animation='pulse' variant='text' sx={{ height: '20px', mt: 0.5, mb: 1.25, transform: 'none' }} width={150} />
      <Skeleton animation='pulse' variant='text' sx={{ height: '37px', mb: 2.25, transform: 'none' }} width={400} />
    </>)}

    {/* Loaded, but no selected form - shouldn't happen, right? */}
    {isAdminFormDataLoaded && ! formData && (<>
      <BackToFormsLink />
      <Typography variant='h1' color='error.main' sx={{ ...pageHeaderSX, mb: 2.25 }}>Selected form NOT found</Typography>
    </>)}

    {/* Loaded, with selected form */}
    {isAdminFormDataLoaded && formData && (<>
      <BackToFormsLink />
      <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 2.25 }}>Edit Form - {formData.name}</Typography>
    </>)}

    <CustomTabs
      ariaTabAndPanelPrefix='edit-form'
      aria-label='Edit Form Tabs'
      allowScrollButtonsMobile
      selectedTab={selectedTab}
      variant='scrollable'
      tabs={[
        { label: 'Basic Info' },
        { label: 'Communications' },
        { label: 'Tags' },
        { label: 'Billing' },
      ]}
      handleTabChange={handleTabChange}
    />
    <CustomTabPanel index={0} selectedTab={selectedTab}>
      <Container sx={containerSX}>
        <FormCrudBasicInfo action='edit' editAccess={editAccess} />
      </Container>
    </CustomTabPanel>
    <CustomTabPanel index={1} selectedTab={selectedTab} autoHeight>
      <Container sx={containerSX}>
        <FormCrudCommunications action='edit' editAccess={editAccess} />
      </Container>
    </CustomTabPanel>
    <CustomTabPanel index={2} selectedTab={selectedTab}>
      <Container sx={containerSX}>
        <FormCrudTags action='edit' editAccess={editAccess} />
      </Container>
    </CustomTabPanel>
    <CustomTabPanel index={3} selectedTab={selectedTab} autoHeight>
      <Container sx={containerSX}>
        <FormCrudBilling action='edit' editAccess={editAccess} />
      </Container>
    </CustomTabPanel>
  </>);
};
export default FormEdit;