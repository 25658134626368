import { useContext, useState } from 'react';
import { AppBar, Avatar, Box, Button, IconButton, Menu, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { UserContext } from '../contexts/UserContext';
import { SideBarDrawerProps } from './SideBarDrawer';
import { inceptiaGreenAlphaColors, menuItemSX } from '../utilities/CSS';
// import { useNavigate } from 'react-router-dom';
import KeycloakService from '../services/KeycloakService';

// const settings = [
//   {id: 0, name: 'Settings', location: '/dashboard/settings'},
//   {id: 1, name: 'Dashboard', location: '/dashboard'},
// ];

function DashboardAppBar({ openSideBarDrawer, setOpenSideBarDrawer }: SideBarDrawerProps) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const userContext = useContext(UserContext);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const navigate = useNavigate();
  let userName = "";
  if (userContext.user?.firstName) userName += userContext.user?.firstName;
  if (userName.length > 1) userName += " ";
  if (userContext.user?.lastName) userName += userContext.user?.lastName;

  return (
    <AppBar position="sticky" elevation={0} color={"secondary"}>
      <Toolbar>
        { ! openSideBarDrawer && (
          <>
            {/* Hamburger menu icon */}
            <IconButton
              size="large"
              aria-label="sidebar open button"
              onClick={() => setOpenSideBarDrawer(true)}
              color="default"
              title='Open sidebar'
              sx={{
                '&:hover': {
                  backgroundColor: inceptiaGreenAlphaColors.hover
                }
              }}
            >
              <MenuIcon />
            </IconButton>

            {/* Inceptia logo */}
            <Box component='img' src={process.env.PUBLIC_URL + '/logo_inceptia.png'} alt='Inceptia logo' sx={{ mx: 1 }} />
          </>
        )}

        {/* Academic year? */}
        {/*
          Need to point the label to the input element generated by MUI for the select instead of the select itself.
          Fixes the "Missing form label" error generated by WAVE.
        */}
        <Typography component='label' htmlFor='academic-year-input' sx={visuallyHidden}>Academic year</Typography>
        <Select
          color='success'
          defaultValue='2024-2025'
          size='small'
          sx={{
            ml: 'auto',
            pl: { md: 0.5 },
            pr: { md: 2 },
            py: 0.5
          }}
          inputProps={{ id: 'academic-year-input' }}
        >
          <MenuItem value='2024-2025' sx={menuItemSX}>2024-2025</MenuItem>
        </Select>

        {/* Signed in user avatar/name and dropdown menu */}
        <Button
          aria-label="dashboard user button"
          aria-controls="user-appbar"
          aria-haspopup="true"
          onClick={handleOpenUserMenu}
          // title={`Open settings for ${userName}`}
          color='inherit'
          sx={{
            ml: 1,
            textTransform: 'none',
            '&:hover': {
              backgroundColor: inceptiaGreenAlphaColors.hover
            },
            '& .MuiTouchRipple-child': {
              color: 'InceptiaGreen.main'
            }
          }}
        >
          <Avatar alt={userName} src={userContext.user?.profileImage || ""} />
          <Typography noWrap sx={{ color: 'grey.800', fontSize: '0.875rem', ml: 1}}>{userName}</Typography>
          <ExpandMoreIcon />
        </Button>
        <Menu
          id="user-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          keepMounted
          slotProps={{
            paper: {
              sx: { minWidth: 180 }
            }
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {/* {settings.map(setting => (
            <MenuItem key={setting.id} onClick={()=>{navigate(setting.location)}} sx={{...menuItemSX, justifyContent: 'flex-end'}}>
              {setting.name}
            </MenuItem>
          ))} */}
          <MenuItem key="Logout" sx={{...menuItemSX, justifyContent: 'flex-end'}} onClick={() => {KeycloakService.doLogout()}}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
export default DashboardAppBar;
