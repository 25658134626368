import React, { useState, FC, useContext } from 'react';
import LoadingContext, { defaultState } from '../contexts/LoadingContext';
import { PageLoader } from './PageLoader';

interface Props {
    children: React.ReactNode;
}

export function LoadingProvider({ children }: Props) {
    const [isLoading, setLoadingState] = useState(defaultState.isLoading);
    const setLoading = (value: boolean) => {
        setLoadingState(value);
    };

    return (
        <LoadingContext.Provider
            value={{
                isLoading,
                setLoading
            }}
        >
            {isLoading? <PageLoader message='loading' /> : children }
        </LoadingContext.Provider>
    );
};

export const useLoading = () => useContext(LoadingContext);