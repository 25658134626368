import {
    Box,
    Button, 
    Grid, 
    Menu, 
    MenuItem, 
    Typography 
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { iUserDTO } from "../utilities/APIInterfaces";
import { 
    formatDate, 
    formatPhoneNumber, 
    formatSSN 
} from "../utilities/HelperFunctions";
import { OrganizationContext } from "../contexts/OrganizationContext";

export default function UserSummary({user}: {user: iUserDTO | undefined}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ userSummaryItems, setUserSummaryItems ] = useState<{title: string, value: string}[]>();
    const { organization } = useContext(OrganizationContext);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getStudentIdFromSchools = (user: iUserDTO | undefined): string => {
        return user?.studentSchools.filter(
            s => s.school_Id === organization?.id)[0]?.school_Student_Id ?? '';
    }

    const smallHeader = {
        fontSize: '0.875rem',
        fontWeight: '600'
    };

    useEffect(() => {
        setUserSummaryItems([
            {title: 'Date of Birth', value: formatDate(user?.dob ?? '')},
            {title: 'SSN', value: formatSSN(user?.last4 ?? '', true)},
            {title: 'Email', value: user?.email ?? ''},
            {title: 'Phone', value: formatPhoneNumber(user?.phone ?? '')},
            {title: 'Student Id', value: getStudentIdFromSchools(user)},
            // Address is not currently being collected from uers, but was added to UI design
            // {title: 'Address', value: user.address},
        ]);
    }, [user]);

    return (<>
        <Typography variant='h3' sx={{ display: 'flex', fontSize: '1.5rem', fontWeight: '500', mb: 3.75 }}>
          User Summary
          {/* <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color='inherit'
            onClick={handleClick}
            sx={{ minWidth: 'revert', ml: 'auto' }}
          >
            <MoreHorizIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'more-button',
                dense: true,
                sx: {
                  minWidth: '180px',
                  textAlign: 'right'
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
          >
            <MenuItem onClick={handleClose}>
              <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Edit User</ListItemText>
            </MenuItem>
          </Menu> */}
        </Typography>
        <Grid container rowSpacing={2.5}>
            {userSummaryItems?.map((item, index) => (
                <Grid
                    item xs={6} 
                    key={index}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: { md: '1fr' },
                            gap: 0.5, 
                        }}
                    >
                        <Typography sx={{ ...smallHeader }}>
                            {item.title}
                        </Typography>
                        <Typography sx={{ fontSize: '0.875rem'}}>
                            {item.value}
                        </Typography>
                    </Box>            
                </Grid>
              ))}
        </Grid>
    </>)
}

