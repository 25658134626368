import { Avatar, Box, Container, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import { cardSX } from '../utilities/CSS';

// Commenting out dummy data for now - FEBS-455 Update the communications on the student dashboard to display the empty state
// function createData(
//     id: number,
//     name: string,
//     message: string,
//     messagedate: string,
//   ) {
//     return { id, name, message, messagedate};
//   }

// const rows: any[] = [
//   createData(0,'Cath - Call Center Specialist', 'Inceptia sends these communications to your students/borrowers', '4:22pm - 8/12/2023'),
//   createData(1,'SUNY - Albany', 'SUNY-Albany sends these communications to your students/borrowers', '3:32pm - 8/11/2023'),
//   createData(2,'Jeff - Call Center Specialist', 'Inceptia sends these communications to your students/borrowers', '10:05pm - 8/01/2023'),
//  ];
const rows: any[] = [];

const EmptyState = () => {
  return (
    <>
      <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500 }}>New Communications</Typography>
      <Box sx={cardSX.emptyStateBox}>
        <SpeakerNotesOffOutlinedIcon sx={{ color: '#eee', fontSize: '5.55rem', mb: 2 }} />
        <Typography variant='body2'>No communications yet.</Typography>
      </Box>
    </>
  );
};

const CommunicationsList = () => {
  return (
    <>
      <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 4.5 }}>New Communications ({rows.length})</Typography>
      <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}>
          <List sx={{ py: 0 }}>
          {rows.map((row) => (
            <ListItem key={row.id} sx={{ px: 0 }}>
                <ListItemAvatar>
                  <Avatar>{row.name.substring(0, 1)}</Avatar>
                </ListItemAvatar>
                <ListItemText 
                    key={row.id}
                    primary={row.name} 
                    primaryTypographyProps={{ fontSize: "0.8rem", fontWeight: "bold" }}
                    secondary={row.message} 
                    secondaryTypographyProps={{ fontSize: "0.75rem" }}
                    sx={{ my: 0, width: 'min-content' }}
                />
                <Typography variant='caption' sx={{
                  fontSize: '0.75rem',
                  fontWeight: '300',
                  whiteSpace: 'nowrap'
                }}>{row.messagedate}</Typography>
            </ListItem>
          ))}
          </List>
      </Box>
    </>
  );
};

export default function Communications() {
  return (
    <Container sx={cardSX.container}>
      {rows.length === 0 && <EmptyState />}

      {rows.length > 0 && <CommunicationsList />}

      {/* <Link
        href='/dashboard/communications'
        underline='hover'
        variant='body1'
        sx={{
          fontWeight: 500,
          mx: 'auto',
          width: 'fit-content'
        }}
      >Go to Communication Center</Link> */}
    </Container>
  );
}
