import { createContext, useContext, useState } from 'react';
import { iKeycloakOption, iStudentSchoolDTO } from '../utilities/APIInterfaces';

export interface iUser {
        id: number | null;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phone: string | null;
        profileImage: string | null;
        authId: string | null;
        smsConsent: boolean;
        ssnDobSet: boolean;
        dob: string | null;
        ssn: string | null;
        last4: string | null;
        schools: iStudentSchoolDTO[];
        mfaOptions : iKeycloakOption[];
}

export type UserContextType = {
        user: iUser | null | undefined;
        setUser: (User: iUser | null) => void;
}

export const UserContext = createContext<UserContextType>({ user: null, setUser: user => console.warn('no user provider') });
export const useUser = () => useContext(UserContext);

interface UserContextProps {
        children?: React.ReactNode
}

function getInitialState() {
        //const user = sessionStorage.getItem('user');
//        const user = window.localStorage.getItem("user");
//        return user ? JSON.parse(user) : null;
        return null;
}

export const UserContextProvider: React.FC<UserContextProps> = ({ children }) => {
        const [user, setUser] = useState<iUser | null>(getInitialState);

        return (
                <UserContext.Provider value={{ user, setUser }}>
                        {children}
                </UserContext.Provider>
        );
}

