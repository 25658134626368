import { Survey } from "survey-react-ui";
import { Model } from 'survey-core';
import { InceptiaLight } from '../themes/surveyjs-inceptia-theme';
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { iAdminFormSubmissionsData } from "../contexts/AdminFormSubmissionsDataContext";
import adminFormsService from "../services/AdminFormsService";
import { inceptiaGreenAlphaColors } from "../utilities/CSS";
import { iFormTag, iUserDTO } from "../utilities/APIInterfaces";
import { Status, StatusLabel, ValidManualStatuses, InvalidManualStatuses } from "../utilities/FormEnums";
import tagService from "../services/TagService";
import { useDisableChangeOptions } from './AdminCenterForm';


interface FormSubmissionProps {
    submission: iAdminFormSubmissionsData;
    setIsServiceHistoryLoaded: (v: boolean) => void;
    verifier_Type_Id: number;
}

export default function FormSubmission(props: FormSubmissionProps) {
    const [model, setModel] = useState<Model | undefined>(undefined);
    const [loaded, setLoaded] = useState(false);
    const [tagOptions, setTagOptions] = useState<{ label: string | undefined; value: number; }[]>([]);
    const [hasTags, setHasTags] = useState<boolean>(true);
    const [selectedTags, setSelectedTags] = useState<{ label: string | undefined; value: number; }[]>([]);
    const [updatingTags, setUpdatingTags] = useState(false);
    const [assigneeOptions, setassigneeOptions] = useState<{ label: string | undefined; value: string; }[]>([]);
    const [selectedAssignee, setSelectedAssignee] = useState<{ label: string | undefined; value: string; }>();
    const [updatingAssignee, setUpdatingAssignee] = useState(false);
    const [statusOptions, setStatusOptions] = useState<{ label: string | undefined; value: number; }[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<{ label: string | undefined; value: number; }>();
    const [updatingStatus, setUpdatingStatus] = useState(false);
    
    const disableChangeOptions = useDisableChangeOptions(props.verifier_Type_Id, ['Institution', 'InstitutionAdmin']);
    
    useEffect(() => {
        const SetupTags = async () => {
            if (props.submission.form_Uuid !== null) {
                await tagService.GetFormTags(props.submission.form_Uuid, (v) => { }).then((result) => {
                    let options: { label: string | undefined; value: number; }[] = [];
                    if(result !== null && result.length > 0){
                        result.forEach((tag: iFormTag) => {
                            options.push({ label: tag.tag_Name, value: tag.id })
                        });
                        mapExistingTags(options);
                        setTagOptions(options);
                    }else{
                        setHasTags(false);
                    }
                }
                );
            }
        }

        const mapExistingTags = (formTags: any) => {
            let selectedTags: { label: string | undefined; value: number; }[] = [];
            if (props.submission.tags !== null) {
                props.submission.tags.forEach((tag: any) => {
                    if (formTags !== undefined) {
                        let tagOption = formTags.find((formTag: any) => formTag.label === tag.tag_Name);
                        if (tagOption !== undefined) {
                            selectedTags.push(tagOption);
                        }
                    }
                });
            }
            setSelectedTags(selectedTags);
        }

        const SetupAssignees = async () => {
            let options: { label: string | undefined; value: string; }[] = [];
            const formUuid = props.submission.form_Uuid || ""; 
            await adminFormsService.GetFormAssignees(formUuid, (v)=>{}).then((result) => {
                result.forEach((user: iUserDTO) => {
                    options.push({ label: `${user.first_Name} ${user.last_Name}`, value: user.auth_Id })
                });
                if (props.submission.assignee !== null) {
                    let assignee = options.find((option) => option.value === props.submission.assignee?.auth_Id);
                    if (assignee !== undefined) {
                        setSelectedAssignee(assignee);
                    }
                }
            })
            setassigneeOptions(options);
        };
        const SetupStatus = () =>{
            let currentStatus = props.submission.status_Id as Status;
            let statusSet = false;
            let options: { label: string | undefined; value: number; }[] = [];
            ValidManualStatuses.forEach((status) => {
                let statusChoice;
                statusChoice = { label: StatusLabel.get(status), value: status }
                if(status === currentStatus){
                    setSelectedStatus(statusChoice);
                    statusSet = true;
                }
                options.push(statusChoice);
            });
            if(!statusSet){
                let statusChoice = { label: StatusLabel.get(currentStatus), value: currentStatus };
                //add the current status to the list of options, but it's not a valid status, the component will account for that
                options.push(statusChoice);
                setSelectedStatus(statusChoice);
            }
            setStatusOptions(options);  

        }

        if (props.submission !== undefined && !loaded) {
            SetupTags()
            SetupAssignees()
            SetupStatus()
            const surveyModel = new Model(props.submission.form_Json);
            if (props.submission.submission_Json !== null) {
                surveyModel.data = JSON.parse(props.submission.submission_Json);
            }
            surveyModel.mode = 'display';
            surveyModel.applyTheme(InceptiaLight);
            setModel(surveyModel);
        }
    }, [props.submission, loaded]);

    useEffect(() => {
        if (assigneeOptions.length > 0 && statusOptions.length > 0 && !loaded) {
            if (hasTags && tagOptions.length > 0) {
                setLoaded(true);
            } else if (!hasTags) {
                setLoaded(true);
            }
        }
    }, [assigneeOptions, tagOptions, hasTags, statusOptions, loaded]);

    useEffect(() => {
        if(updatingTags){
            tagService.SetSubmissionsTags(props.submission.form_Uuid!, [props.submission.uuid], selectedTags.map((tag) => tag.value), true, (v) => {}).then((result) => {
                //alert here?
            }).finally(() => {
                setUpdatingTags(false);
                props.setIsServiceHistoryLoaded(false);
            })
        }
    }, [selectedTags]);

    useEffect(() => {
        if (updatingAssignee) {
            adminFormsService.UpdateSubmisionAssignee([props.submission.uuid], selectedAssignee?.value ?? '', (v) => { }).then((result) => {
                //alert here?
            }).finally(() => {
                setUpdatingAssignee(false);
                props.setIsServiceHistoryLoaded(false);
            });
        }
    }, [updatingAssignee]);

    useEffect(() => {
        if (updatingStatus) {
            adminFormsService.UpdateSubmissionStatuses([props.submission.uuid], selectedStatus?.value as Status , (v) => { }).then((result) => {
                //alert here?
            }).finally(() => {
                setUpdatingStatus(false);
                props.setIsServiceHistoryLoaded(false);
            });
        }
    }, [updatingStatus]);

    return (
        <>
        {loaded && (
        <Grid container rowSpacing={2}>
            <Grid 
                item xs={4} 
                key={0}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    mt: 3,
                    pl: 3
                }}
            >
                <Autocomplete
                    id={`change-assignee-autocomplete`}
                    options={assigneeOptions}
                    value={selectedAssignee}
                    disabled={updatingAssignee || disableChangeOptions}
                    loading={assigneeOptions.length === 0}
                    onChange={(e, value: any) => {
                        setSelectedAssignee(value);
                        setUpdatingAssignee(true);
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={'Assignee'}
                            InputLabelProps={{ shrink: true }}
                            />}
                    //Style the selected items in the dropdown list
                    slotProps={{
                        popper: {
                            sx: {
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.hover
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                                    backgroundColor: inceptiaGreenAlphaColors.selected
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.selectedHover
                                },
                                
                            }
                        }
                    }}
                    sx={{ width: '98%' }}
                />
            </Grid>
            <Grid 
                item xs={4} 
                key={1}                 
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                }}
            >
                <Autocomplete
                    id={`change-tags-autocomplete`}
                    getOptionLabel={(option) => option.label || ''}
                    multiple={true}
                    options={tagOptions}
                    value={selectedTags}
                    disabled={updatingTags || disableChangeOptions}
                    loading={tagOptions.length === 0}
                    onChange={(e, value: any) => {
                        setSelectedTags(value);
                        setUpdatingTags(true);
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label='Tags'
                            InputLabelProps={{ shrink: true }}
                            />}
                    //Style the selected items in the dropdown list
                    slotProps={{
                        popper: {
                            sx: {
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.hover
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                                    backgroundColor: inceptiaGreenAlphaColors.selected
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.selectedHover
                                },
                                
                            }
                        }
                    }}
                    sx={{ width: '98%' }}
                />
            </Grid> 
            <Grid 
                item xs={4} 
                key={2}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 3,
                    pr: 3
                }}
            >
                <Autocomplete
                    id={`change-status-autocomplete`}
                    options={statusOptions}
                    value={selectedStatus}
                    disabled={updatingStatus || disableChangeOptions}
                    disableClearable={true}
                    loading={statusOptions.length === 0}
                    onChange={(e, value: any) => {
                        setSelectedStatus(value);
                        setUpdatingStatus(true);
                    }}
                    getOptionDisabled={(option) => InvalidManualStatuses.has(option.value as Status)}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={'Status'}
                            InputLabelProps={{ shrink: true }}
                            />}
                    //Style the selected items in the dropdown list
                    slotProps={{
                        popper: {
                            sx: {
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.hover
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                                    backgroundColor: inceptiaGreenAlphaColors.selected
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.selectedHover
                                },
                                
                            }
                        }
                    }}
                    sx={{ width: '98%' }}
                />
            </Grid>
            <Grid 
                item xs={12}
                sx={{ mt: 1 }}
            >
                {model &&  <Survey model={model}/>}
            </Grid>
        </Grid>
        )}
    </>
    )
}


