export enum AutoSaveState{
    INITIAL,
    LOADING,
    SAVE_NEEDED,
    DATA_NOT_CHANGED
};

export enum Status{
    INCOMPLETE = 1,
    SUBMITTED = 2,
    DECLINED = 3,
    APPROVED = 4,
    IN_REVIEW = 5,
    ACTION_NEEDED = 6,
    SUSPENDED = 7,
    INELIGIBLE = 8
};

export const StatusLabel = new Map<number | null | undefined, string>([
    [null, 'Not Started'],
    [undefined, 'Not Started'],
    [Status.INCOMPLETE, "Incomplete"],
    [Status.SUBMITTED, "Submitted"],
    [Status.DECLINED, "Declined"],
    [Status.APPROVED, "Approved"],
    [Status.IN_REVIEW, "In Review"],
    [Status.ACTION_NEEDED, "Action Needed"],
    [Status.SUSPENDED, "Suspended"],
    [Status.INELIGIBLE, "Ineligible"]
]);

export const ValidManualStatuses = new Set<Status>([
    Status.DECLINED,
    Status.APPROVED, 
    Status.IN_REVIEW,
    Status.ACTION_NEEDED
])

export const InvalidManualStatuses = new Set<Status>([
    Status.INCOMPLETE,
    Status.SUBMITTED,
    Status.SUSPENDED,
    Status.INELIGIBLE
])

export enum Priority {
    REQUIRED = 1,
    OPTIONAL = 2
}
export const PriorityLabel = new Map<number, string>([
    [Priority.REQUIRED, 'Required'],
    [Priority.OPTIONAL, 'Optional']
]);
export const PriorityNumber = new Map<string, number>([
  ['Required', Priority.REQUIRED],
  ['Optional', Priority.OPTIONAL]
]);

export enum BillingType {
  ANNUAL_SUBSCRIPTION = 1,
  FORM_STATUS = 2
}
export const BillingTypeLabel = new Map<number, string>([
  [BillingType.ANNUAL_SUBSCRIPTION, 'Annual subscription fee'],
  [BillingType.FORM_STATUS, 'By form status(es)']
]);
export const BillingTypeNumber = new Map<string, number>([
  ['Annual subscription fee', BillingType.ANNUAL_SUBSCRIPTION],
  ['By form status(es)', BillingType.FORM_STATUS]
]);

export enum VerifierType {
  INCEPTIA = 1,
  INSTITUTION = 2
}
export const VerifierTypeLabel = new Map<number, string>([ 
  [VerifierType.INCEPTIA, 'Inceptia'],
  [VerifierType.INSTITUTION, 'Institution']
]);
export const VerifierTypeNumber = new Map<string, number>([
  ['Inceptia', VerifierType.INCEPTIA],
  ['Institution', VerifierType.INSTITUTION]
]);

export enum VisibilityType {
  PUBLIC = 1,
  PRIVATE = 2
}
export const VisibilityTypeLabel = new Map<number, string>([
  [VisibilityType.PUBLIC, 'Public'],
  [VisibilityType.PRIVATE, 'Private']
]);
export const VisibilityTypeNumber = new Map<string, number>([
  ['Public', VisibilityType.PUBLIC],
  ['Private', VisibilityType.PRIVATE]
]);

export enum TaskType {
    VERIFICATION = 1
}

export const TaskTypeLabel = new Map<number | null | undefined, string>([
    [null, ''],
    [undefined, ''],
    [TaskType.VERIFICATION, 'Verification']
]);

export enum SubmissionType {
    STUDENT = 1
}

export const SubmissionTypeLabel = new Map<number | null | undefined, string>([
    [null, ''],
    [undefined, ''],
    [SubmissionType.STUDENT, 'Student']
]);

export enum MfaType {
    SMS,
    AUTHENTICATOR_APP
}

export const MfaTypeLabel = new Map<number | null | undefined, string>([
    [MfaType.SMS, 'SMS'],
    [MfaType.AUTHENTICATOR_APP, 'Authenticator App']
]);

export enum ServiceHistoryType {
  EMAIL = 1,
  TEXT = 2,
  FORM = 3,
  COMMENT = 4
}
export const ServiceHistoryTypeLabel = new Map<number, string>([
  [ServiceHistoryType.EMAIL, 'Email notification'],
  [ServiceHistoryType.TEXT, 'Text notification'],
  [ServiceHistoryType.FORM, 'Form update'],
  [ServiceHistoryType.COMMENT, 'Comment']
]);
