import AdminCenterForm from '../components/AdminCenterForm';
import { AdminFormDataContextProvider } from '../contexts/AdminFormDataContext';
import { AdminFormSubmissionsDataContextProvider } from '../contexts/AdminFormSubmissionsDataContext';

export default function AdminForm() {
  return (
    <>
      <AdminFormSubmissionsDataContextProvider>
        <AdminFormDataContextProvider>
          <AdminCenterForm />
        </AdminFormDataContextProvider>
      </AdminFormSubmissionsDataContextProvider>
    </>
  );
}
