import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import Container from '@mui/material/Container/Container';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Switch,
  List, ListItem,
  ListItemText,
  TextField
} from "@mui/material";
import { inceptiaGreenAlphaColors } from "../utilities/CSS";
import CloseIcon from '@mui/icons-material/Close';
import userService from "../services/UserService";
import KeycloakService from "../services/KeycloakService";
import { MfaType } from "../utilities/FormEnums";
import { Navigate, useNavigate } from "react-router-dom";

const actionUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/`
  + `realms/${process.env.REACT_APP_KEYCLOAK_REALM}/`
  + `protocol/openid-connect/auth`
  + `?client_id=${process.env.REACT_APP_KEYCLOAK_DASHBOARD_CLIENT}`
  + `&redirect_uri=${encodeURIComponent(window.location.href)}`
  + `&response_mode=query`
  + `&response_type=code`
  + `&scope=openid`
  + `&kc_action=`;

export default function ProfileFormSecurity() {
  const userContext = useContext(UserContext);
  const [enablePreference, setEnablePreference] = useState<boolean>(false);
  const [mfaPreference, setMfaPreference] = useState<MfaType>();
  const [open, setOpen] = useState(false);
  const [disableInput, setDisableInput] = useState(false); 
  const navigate = useNavigate();

  const smsMFACodeName = 'mobile-number';
  const authenticatorAppMFACodeName = 'otp';



  const handleEditPassClose = () => {
    setOpen(false);
  };

  function handleForget(mfaType : MfaType): void {
    setDisableInput(true);
    let credentialId = userContext.user?.mfaOptions.find(mfa => mfa.type == (mfaType == MfaType.SMS ? smsMFACodeName : authenticatorAppMFACodeName))?.id;
    if(credentialId != undefined){
      userService.ForgetCredential(KeycloakService.getId(), credentialId, () => { }).then((forceSetup) => {
        if (forceSetup){
          window.location.href = actionUrl+`CONFIGURE_TOTP`;
        }
        else{
          userService.GetUser(KeycloakService.getId(), (v) => v)
            .then((result) => {
              let user = userService.MapToUser(result);
              userContext.setUser(user);
            });
        }
      });
    }
    
    setDisableInput(false);
  }

  function handleChangePreference(mfaType : MfaType): void {
    setDisableInput(true);
    userService.UpdateMfaPreference(KeycloakService.getId(), mfaType, () => { }).then(() => {
      userService.GetUser(KeycloakService.getId(), (v) => v)
        .then((result) => {
          let user = userService.MapToUser(result);
          userContext.setUser(user);
          setMfaPreference(mfaType);
        });
    });
    setDisableInput(false);
  }

  useEffect(() => {
    let enablePreference = userContext.user?.mfaOptions.length ==2
    if(enablePreference){
      if(userContext.user?.mfaOptions[0].type == smsMFACodeName){
        setMfaPreference(MfaType.SMS);
      }else{
        setMfaPreference(MfaType.AUTHENTICATOR_APP);
      }
    }
    setEnablePreference(enablePreference);
  }, [userContext.user]);

  return (
    <Container sx={{ p: { xs: 2.25, sm: 4.5 } }}>
      <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 5.5 }}>Password</Typography>

      <TextField
        disabled
        fullWidth
        label='Current Password'
        size='small'
        inputProps={{
          // Attempt to vertically center the stars in the disabled field
          sx: { pt: '17px', pb: '10px' }
        }}
        InputLabelProps={{
          shrink: true,
          sx: {
            color: 'grey.800',
            '&.Mui-disabled': {
              color: 'grey.800'
            }
          }
        }}
        sx={{
          maxWidth: '445px'
        }}
        value='**********'
      />

      <Box sx={{ mt: 1, mb: 4.5 }}>
        <Link
          title='Edit password'
          // This is a temporary way to change password through keycloak, until we can get API calls in place
          href={actionUrl+`UPDATE_PASSWORD`}
          sx={{
            color: 'var(--link-color)',
            cursor: 'pointer',
            display: 'inline-block',
            fontSize: '0.875rem',
            fontWeight: 600,
            textDecoration: 'none'
          }}
        // onClick={handleEditPassOpen}
        >Edit</Link>
      </Box>

      <Dialog
        open={open}
        onClose={handleEditPassClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            console.log(formJson);
            handleEditPassClose();
          },
        }}
      >
        <DialogTitle>Edit Current Password</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleEditPassClose}
          sx={{
            position: 'absolute',
            top: 3,
            right: 3
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="old-field"
            name="oldPassword"
            label="Old Password"
            type="password"
            fullWidth
          />
          <TextField
            required
            margin="dense"
            id="new-field"
            name="newPassword"
            label="New Password"
            type="password"
            fullWidth
          />
          <TextField
            required
            margin="dense"
            id="confirm-field"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            fullWidth
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center'
          }}
        >
          <Button
            onClick={handleEditPassClose}
            variant='outlined'
            color='inherit'
            sx={{
              width: '45%',
              fontSize: '1rem',
              textTransform: 'none',
              '&:hover, &:focus': {
                backgroundColor: inceptiaGreenAlphaColors.hover
              }
            }}
          >Cancel</Button>
          <Button
            type="submit"
            variant='contained'
            sx={{
              mt: 3,
              mb: 3,
              width: '45%',
              bgcolor: 'InceptiaGreen.main',
              fontSize: '1rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'InceptiaGreen.dark',
              },
            }}
          >Done</Button>
        </DialogActions>
      </Dialog>

      <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 2.5 }}>Multi-Factor Authentication</Typography>

      <List>
        <ListItem>
          <ListItemText
            primary='SMS'
            secondary='Text Message'
          />
          {
            userContext.user?.mfaOptions.find(mfa => mfa.type === smsMFACodeName) == undefined &&
            <Button
              disabled={disableInput}
              variant='contained'
              size='small'
              sx={{
                bgcolor: 'InceptiaGreen.main',
                fontSize: '1rem',
                px: 5,
                textTransform: 'none',
                marginRight: '35%',
                '&:hover': {
                  backgroundColor: 'InceptiaGreen.dark',
                }
              }}
              // onClick={handleSaveClick}
              href={actionUrl+`mobile_number_config`}
            >Setup</Button>
            ||
            <Button
              disabled={disableInput}
              variant='contained'
              size='small'
              sx={{
                bgcolor: 'InceptiaGreen.main',
                fontSize: '1rem',
                px: 5,
                textTransform: 'none',
                marginRight: '35%',
                '&:hover': {
                  backgroundColor: 'InceptiaGreen.dark',
                }
              }}
              onClick={(event)=>{handleForget(MfaType.SMS)}}
            >Forget</Button>
          }
        </ListItem>

        <ListItem>
          <ListItemText
            primary='Authenticator App'
            secondary='Duo, Google Authenticator, Authy, etc.'
          />
          {
            userContext.user?.mfaOptions.find(mfa => mfa.type === authenticatorAppMFACodeName) == undefined &&
            <Button
              disabled={disableInput}
              variant='contained'
              size='small'
              sx={{
                bgcolor: 'InceptiaGreen.main',
                fontSize: '1rem',
                px: 5,
                textTransform: 'none',
                marginRight: '35%',
                '&:hover': {
                  backgroundColor: 'InceptiaGreen.dark',
                }
              }}
              href={actionUrl+`CONFIGURE_TOTP`}
            >Setup</Button>
            ||
            <Button
              disabled={disableInput}
              variant='contained'
              size='small'
              sx={{
                bgcolor: 'InceptiaGreen.main',
                fontSize: '1rem',
                px: 5,
                textTransform: 'none',
                marginRight: '35%',
                '&:hover': {
                  backgroundColor: 'InceptiaGreen.dark',
                }
              }}
              onClick={(event)=>{handleForget(MfaType.AUTHENTICATOR_APP)}}
            >Forget</Button>
          }
        </ListItem>
      </List>

      {
        enablePreference &&
        <>
          <Typography variant='body1' sx={{ fontSize: '1rem', fontWeight: 400, mb: 4.5 }}>
            You have multiple MFA options available. Select your preferred method below.
          </Typography>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>SMS</Grid>
            <Grid item>
              <Switch
                disabled={disableInput}
                checked={mfaPreference == MfaType.AUTHENTICATOR_APP}
                onChange={(event) => handleChangePreference(event.target.checked ? MfaType.AUTHENTICATOR_APP : MfaType.SMS)}
              />
            </Grid>
            <Grid item>Authenticator App</Grid>
          </Grid>
        </>
      }
    </Container>
  );
}