import { Container } from '@mui/material';
import DashboardAppBar from '../components/DashboardAppBar';
import DashboardLeftNav from '../components/DashboardLeftNav';
import DashboardCenterSettings from '../components/DashboardCenterSettings';
import MainContentContainer from '../components/MainContentContainer';
import SideBarDrawer, { SideBarDrawerProps } from '../components/SideBarDrawer';
import { RememberDeviceButtonProps } from '../components/RememberDeviceButton';

interface DashboardSettingsProps {
  sideBarDrawerProps: SideBarDrawerProps;
  // rememberDeviceButtonProps: RememberDeviceButtonProps;
}

export default function DashboardSettings(props: DashboardSettingsProps) {
  const { widthUnderMd, openSideBarDrawer, setOpenSideBarDrawer } = props.sideBarDrawerProps;
  return (
    <>
      <MainContentContainer widthUnderMd={widthUnderMd} openSideBarDrawer={openSideBarDrawer}>
        <DashboardAppBar {...props.sideBarDrawerProps} />

        <Container sx={{ py: 4 }}>
          <DashboardCenterSettings/> 
        </Container>
      </MainContentContainer>

      <SideBarDrawer
        variant={(widthUnderMd && openSideBarDrawer) ? 'temporary' : 'permanent'}
        open={openSideBarDrawer}
        onClose={() => setOpenSideBarDrawer(false)}
      >
        <DashboardLeftNav {...props.sideBarDrawerProps} />
      </SideBarDrawer>
    </>
  );
}
