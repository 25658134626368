import { 
  Box, 
  Button, 
  ListItemText, 
  Menu, 
  MenuItem, 
  Switch, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography 
} from '@mui/material';
import { AdminFormCrudActionType, AdminFormCrudProps } from '../../pages/AdminFormCrud';
import { visuallyHidden } from '@mui/utils';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { inceptiaGreenAlphaColors } from '../../utilities/CSS'
import { useState } from 'react';

const comTableSx = {
  tableHead: {
    fontSize: '14px',
    fontWeight: '500',
    p: 1.5,
    pt: 0,
    textAlign: 'left',
    textTransform: 'uppercase'
  },
  tableCell: {
    textAlign: 'left'
  }
};

type CommunicationData = {
  name: string;
  type: string;
  trigger: string;
  frequency: string;
  toggle: boolean;
}

const mockData: CommunicationData[] = [{
    name: 'Form Accepted Email',
    type: 'Email',
    trigger: 'Accepted',
    frequency: 'Once',
    toggle: true
  },{
    name: 'Form Accepted Text',
    type: 'Text',
    trigger: 'Accepted',
    frequency: 'Once',
    toggle: false
  },
];

const FormCrudCommunications = (props: AdminFormCrudProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //TODO: Mock data until waterfield is done, likely should be mapped over prop instead 
  const [data, setData] = useState(mockData);
  const open = Boolean(anchorEl);

  function handleRowClick(row: CommunicationData): void {
    console.log(row);
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    event.stopPropagation();
    // TODO: Implement when waterfield is done, Just changing state on mock data for switch to look like it's working
    setData((original) => {
      original[index].toggle = event.target.checked;

      return [...original]
    });
  };

  return (<>
    <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 4.5 }}>Communications</Typography>
    <TableTemplate action={props.action} children={<>
      {data.map((row, index) => {
        return <TableRow
          hover
          role='button' // Keyboard accessibility
          key={index}
          sx={{
            cursor: 'pointer',
            //  Figma screen is showing the bottom border of the last row
            // '&:last-child td, &:last-child th': { border: 0 },
            '&:focus-visible': { backgroundColor: inceptiaGreenAlphaColors.hover }
          }}
          tabIndex={0} // Keyboard accessibility
          onClick={() => handleRowClick(row)}
          onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) => {
            // Keyboard accessibility - open form when pressing enter or space
            if (e.key === 'Enter' || e.key === ' ') handleRowClick(row);
          }}
        >
          <TableCell sx={{
            color: 'InceptiaGreen.main',
            fontSize: '1rem',
            fontWeight: '500',
            px: 1
          }}>{row.name}</TableCell>

          <TableCell sx={comTableSx.tableCell}>{row.type}</TableCell>
          <TableCell sx={comTableSx.tableCell}>Status: {row.trigger}</TableCell>
          <TableCell sx={comTableSx.tableCell}>{row.frequency}</TableCell>

          <TableCell sx={comTableSx.tableCell}>
            <Switch 
              checked={row.toggle}
              onChange={(event) => handleToggleChange(event, index)}
            />
          </TableCell>

          <TableCell sx={{...comTableSx.tableCell, pr: 0 }}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color='inherit'
            onClick={handleMenuClick}
            sx={{ minWidth: 'revert', ml: 'auto' }}
          >
            <MoreHorizIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
                'aria-labelledby': 'more-button',
                dense: true,
                sx: {
                  minWidth: '180px',
                  textAlign: 'right'
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
          >
            <MenuItem onClick={handleMenuClose}>
              <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Delete Communication</ListItemText>
            </MenuItem>
          </Menu>
          </TableCell>
        </TableRow>
    })}</>}/>
  </>);
};

const TableTemplate = ({ action, children }: { action: AdminFormCrudActionType, children: React.ReactNode }): JSX.Element => {
  return (
    <TableContainer sx={{
      ...(action === 'add' && { mb: 6.25 })
    }}>
      <Table aria-label="to-do list table" sx={{justifyContent: 'left'}}>
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={comTableSx.tableHead}>Name</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>Type</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>Trigger</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>Frequency</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>On/Off</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>
              {/* Fix for empty header cell accessibility error */}
              <Box sx={visuallyHidden}>Actions</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default FormCrudCommunications;
