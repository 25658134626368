import { useLocation, useNavigate } from 'react-router-dom';
import { Box, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MenuOpen from '@mui/icons-material/MenuOpen';
import { sideBarDrawerWidth, SideBarDrawerProps } from './SideBarDrawer';
import { inceptiaGreenAlphaColors, listItemSX } from '../utilities/CSS';

export default function DashboardLeftNav({ widthUnderMd, setOpenSideBarDrawer }: SideBarDrawerProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const mainItems = [
    {id: 1, name: 'Home', path: ['/', '/login', '/dashboard']},
    // {id: 2, name: 'Communication Center', path: ['/dashboard/communications']},
  ];
  const secondaryItems = [
    // {id: 3, name: 'Documents', path: '/dashboard/documents'},
    {id: 4, name: 'Settings', path: '/dashboard/settings'}
  ];

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    switch(index) {
      case 2:
        navigate("/dashboard/communications");
        break;

      case 3:
        navigate("/dashboard/documents");
        break;

      case 4:
        navigate("/dashboard/settings");
        break;
            
      default:
        navigate("/dashboard");
        break;
    }
    if (widthUnderMd) setOpenSideBarDrawer(false);
  };

  return (
    <Box position='fixed' sx={{ 
      width: sideBarDrawerWidth,
      bgcolor: 'background.paper',
      height: '100%',
      overflowY: 'auto',
      px: 1.5
    }}>
      <Stack direction='row' alignItems='center' sx={{ mb: 0.75, pt: 1.5 }}>
        <Box component='img' src={process.env.PUBLIC_URL + '/logo_inceptia.png'} alt='Inceptia logo' />

        <IconButton
          size="large"
          aria-label="sidebar close button"
          onClick={() => setOpenSideBarDrawer(false)}
          color="default"
          title='Close sidebar'
          sx={{
            ml: 'auto',
            '&:hover': {
              backgroundColor: inceptiaGreenAlphaColors.hover
            }
          }}
        >
          {widthUnderMd ? <CloseIcon /> : <MenuOpen />}
        </IconButton>
      </Stack>

      <List component="nav" aria-label="main mailbox folders">
        <ListSubheader component="div">
          STUDENT DASHBOARD
        </ListSubheader>
        {mainItems.map(item => (
          <ListItemButton
            key={item.id}
            selected={item.path.includes(pathname)}
            onClick={(event) => handleListItemClick(event, item.id)}
            sx={{
              ...listItemSX,
              borderRadius: '4px',
              py: 2,
              pl: 3
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px' }}>
              {item.name === 'Home' ? <HomeOutlinedIcon /> : <HeadsetMicOutlinedIcon />}
            </ListItemIcon>
            <ListItemText
              sx={{ my: 0 }}
              primaryTypographyProps={{fontSize: '0.875rem', fontWeight: 'bold'}}
              primary={item.name} />
          </ListItemButton>
        ))}
      </List>

      <List component="nav" aria-label="secondary mailbox folder">
        <ListSubheader component="div">
          GENERAL
        </ListSubheader>
        {secondaryItems.map(item => (
          <ListItemButton
            key={item.id}
            selected={pathname === item.path}
            onClick={(event) => handleListItemClick(event, item.id)}
            sx={{
              ...listItemSX,
              borderRadius: '4px',
              py: 2,
              pl: 3
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px' }}>
              {item.name === 'Documents' ? <FolderOutlinedIcon /> : <SettingsOutlinedIcon />}
            </ListItemIcon>
            <ListItemText 
              sx={{ my: 0 }}
              primaryTypographyProps={{fontSize: '0.875rem', fontWeight: 'bold'}}
              primary={item.name} />
          </ListItemButton>
        ))}
     </List>
    </Box>
  );
}