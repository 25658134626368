import { AdminFormSubmissionsDataContextProvider } from '../contexts/AdminFormSubmissionsDataContext';
import AdminCenterUsers from '../components/AdminCenterUsers';

export default function AdminUsers() {
  return (
    <>
      <AdminFormSubmissionsDataContextProvider>
          <AdminCenterUsers />
      </AdminFormSubmissionsDataContextProvider>
    </>
  );
}
