export interface iUserDTO {
  id: number | null;
  first_Name: string;
  last_Name: string;
  last4: string | null;
  email: string;
  phone: string;
  auth_Id: string;
  ssn: string | null;
  dob: string | null;
  sms_Consent: boolean;
  ssn_Dob_Set: boolean;
  studentSchools: iStudentSchoolDTO[];
  mfaOptions: iKeycloakOption[];
}

export interface iUserServiceHistoryDTO {
  id: number;
  source: string | null;
  type: number;
  description: string | null;
  body: string | null;
  action_Date: string | null;
  commenter_Name: string | null;
  commenter_Id: number | null;
}

export interface iUserServiceHistoryCommentDTO {
  id: number;
  auth_Id: string;
  commenter_Id: number;
  submission_Forms_Id: number;
  submission_Forms_Uuid: string;
  created_On: string;
  comment: string;
}

export interface iStudentSchoolDTO {
  id: number | null;
  user_Id: number | null;
  school_Student_Id: string | null;
  school_Name: string | null;
  school_Id: number | null;
  purl: string | null;
}

export interface iOrgDTO {
  id: number;
  org_Type_Id: number;
  name: string;
  city: string | null;
  state: string | null;
  address: string | null;
  address2: string | null;
  zip: string | null;
  opeid: string;
  school_Code: string;
  source_Id: number;
  last_Update_Date: Date;
  add_Date: Date;
  purl: string;
}

export interface iSubmission {
    id: number | null;
    uuid: string | undefined;
    forms_Version_Id: number;
    org_Id: number;
    submission_Type_Id: number | null;
    status_Id: number | null;
    submitted_By: number;
    submission_Json: string;
    documents: iDocumentDTO[];
    is_Retaking_Form: boolean;
    is_Submitting_Form: boolean;
}

export interface iTagsDTO {
  id: number;
  tag_Name: string;
  org_Id: number;
}

export interface iAdminTag {
  id: number;
  tag_Name: string;
  forms_Using: number;
  submissions_Using: number;
}

export interface iFormTag {
  id: number;
  tag_Name: string;
  org_Id: number;
  forms_Id: number;
}

export interface iFormsInvoiceStatusDTO {
  id: number;
  forms_Id: number;
  status_Id: number;
  created_On: string;
}

export interface iDocumentDTO {
  id: number;
  uuid: string;
  file_Type_Id: number | null;
  file_Path: string | null;
  file_Name: string;
  original_File_Name: string;
  upload_Date: string | null;
  user_Id: number;
}

export interface iTodo {
  form_Id: number;
  form_Uuid: string;
  form_Version_Id: number;
  form_Version_Uuid: string;
  org_Id: number;
  organization: string | null;
  name: string;
  description: string | null;
  priority_Id: number;
  task_Type_Id: number | null;
  status_Id: number | null;
  due_Date: string | null;
  form_Submission_Id: number | null;
  form_Submission_Uuid: string;
  submission_Type_Id: number | null;
  form_Json: string;
  can_Retake: boolean;
}

export class AdminRoleNodes {
  admin?: React.ReactNode | null;
  institutionAdmin?: React.ReactNode | null;
  institution?: React.ReactNode | null;
  srOperations?: React.ReactNode | null;
  operations?: React.ReactNode | null;
  manager?: React.ReactNode | null;
}

export class iKeycloakOption{
  id: string=""
  type: string=""
}