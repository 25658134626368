import { createContext, useContext, useState } from 'react';


export interface iFormData {
    readonly [index: string]: number | string | boolean | Array<any> | null;
    id: number;
    task: string | null;
    priority: string | null;
    owner: string | null;
    institution: string | null;
    tasktype: string | null;
    duedate: string | null;
    status: string | null;
    url: string | null;
    selected: boolean | null;
    submissionId: number;
    formJson: string;
}

export type FormDataContextType = {
        formData: iFormData[] | null | undefined;
        setFormData: (Form: iFormData[] | null) => void;
}

export const FormDataContext = createContext<FormDataContextType>({ formData: null, setFormData: formData => console.warn('no form provider') });
export const useFormData = () => useContext(FormDataContext);

interface FormsContextProps {
        children?: React.ReactNode
}

function getInitialState() {
    return [];
}

export const FormDataContextProvider: React.FC<FormsContextProps> = ({ children }) => {
        const [formData, setFormData] = useState<iFormData[] | null>(getInitialState);

        return (
                <FormDataContext.Provider value={{ formData, setFormData }}>
                        {children}
                </FormDataContext.Provider>
        );
}