import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import adminFormsService from '../services/AdminFormsService';
import { Status } from '../utilities/FormEnums';
import { iUserDTO } from '../utilities/APIInterfaces';

export interface iAdminFormSubmissionsData {
  uuid: string;
  auth_Id: string;
  first_Name: string | null;
  last_Name: string | null;
  name: string | null;
  ssn: string | null;
  last4: string | null;
  school_Student_Id: string | null;
  dob: string | null;
  email: string | null;
  phone: string | null;
  attachments: number | null;
  last_Updated: string | null;
  assignee_Uuid: string | null;
  assignee: iUserDTO | null;
  tags: any[] | [];
  status_Id: number | null;
  submitted_Date: string | null;
  form_Json: string | null;
  submission_Json: string | null;
  form_Uuid: string | null;
  selected: boolean;
};

type AdminFormSubmissionsDataContextType = {
  adminFormSubmissionsData: iAdminFormSubmissionsData[] | null | undefined;
  setAdminFormSubmissionsData: (value: iAdminFormSubmissionsData[] | null) => void;
  adminFormSubmissionsStatusTotals: {};
  setAdminFormSubmissionsStatusTotals: (value: {}) => void;
  isAdminFormSubmissionsDataLoaded: boolean;
  setIsAdminFormSubmissionsDataLoaded: (value: boolean) => void;
  adminFormSubmissionsDataOptions: AdminFormSubmissionsDataOptionsType;
  setAdminFormSubmissionsDataOptions: (value: AdminFormSubmissionsDataOptionsType) => void;
  setForceRefresh: (value: boolean) => void;
  adminFormSubmissionsDataTotalCount: number;
};

export const AdminFormSubmissionsDataContext = createContext<AdminFormSubmissionsDataContextType>({
  adminFormSubmissionsData: null,
  setAdminFormSubmissionsData: value => console.warn('no admin form submissions provider'),
  adminFormSubmissionsStatusTotals: {},
  setAdminFormSubmissionsStatusTotals: value => console.warn('no admin form submissions provider'),
  isAdminFormSubmissionsDataLoaded: false,
  setIsAdminFormSubmissionsDataLoaded: value => console.warn('no admin form submissions provider'),
  adminFormSubmissionsDataOptions: {
    formUuid: '',
    statuses: [],
    searchQuery: '',
    statusChanged: false,
    sortField: '',
    sortDirection: 0,
    assignedToUuids: [],
    tags: [],
    dateRange: [],
    page: 0,
    rowsPerPage: 10
  },
  setAdminFormSubmissionsDataOptions: value => console.warn('no admin form submissions provider'),
  setForceRefresh: value => console.warn('no admin form submissions provider'),
  adminFormSubmissionsDataTotalCount: 0
});
export const useAdminFormSubmissionsData = () => useContext(AdminFormSubmissionsDataContext);

interface AdminFormSubmissionsContextProps {
  children?: React.ReactNode
};

export type AdminFormSubmissionsFilterType = 'All' | Status;

export type AdminFormSubmissionsDataOptionsType = {
  formUuid: string;
  statuses: AdminFormSubmissionsFilterType[];
  searchQuery: string;
  statusChanged: boolean;
  sortField: string;
  sortDirection: 0 | 1; // 0 = ascending, 1 = descending
  assignedToUuids: string[];
  tags: string[];
  dateRange: string[];
  page: number;
  rowsPerPage: number;
};

export const AdminFormSubmissionsDataContextProvider: React.FC<AdminFormSubmissionsContextProps> = ({ children }) => {
  const [adminFormSubmissionsData, setAdminFormSubmissionsData] = useState<iAdminFormSubmissionsData[] | null>(null);
  const [adminFormSubmissionsStatusTotals, setAdminFormSubmissionsStatusTotals] = useState({});
  const [isAdminFormSubmissionsDataLoaded, setIsAdminFormSubmissionsDataLoaded] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [adminFormSubmissionsDataTotalCount, setAdminFormSubmissionsDataTotalCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { formUuid } = useParams();
  
  const [adminFormSubmissionsDataOptions, setAdminFormSubmissionsDataOptions] = useState<AdminFormSubmissionsDataOptionsType>({
    formUuid: formUuid ?? '',
    statuses: [],
    searchQuery: '',
    statusChanged: false,
    sortField: '',
    sortDirection: 0,
    assignedToUuids: [],
    tags: [],
    dateRange: [],
    page: 0,
    rowsPerPage: 10
  });
  async function GetFormSubmissionsData(options: AdminFormSubmissionsDataOptionsType) {
    await adminFormsService.GetFormSubmissionsAndStatusTotals(options, (v) => v)
      .then(result => {
        const { totalCount, submissions, statusTotals } = result;
        submissions ? setAdminFormSubmissionsData(submissions as iAdminFormSubmissionsData[]) : setAdminFormSubmissionsData([]);
        statusTotals ? setAdminFormSubmissionsStatusTotals(statusTotals) : setAdminFormSubmissionsStatusTotals({});
        setAdminFormSubmissionsDataTotalCount(totalCount);
      })
      .catch((error) => {
        enqueueSnackbar(error.toString());
        console.log("GetFormSubmissionsData 1", error);
      }).finally(() => {
        setIsAdminFormSubmissionsDataLoaded(true);
      });
  }
  useEffect(() => {
    if (adminFormSubmissionsDataOptions?.formUuid) GetFormSubmissionsData(adminFormSubmissionsDataOptions);
  }, [enqueueSnackbar, adminFormSubmissionsDataOptions]);

  useEffect(() => {
    if (forceRefresh) {
      GetFormSubmissionsData(adminFormSubmissionsDataOptions);
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  return (
    <AdminFormSubmissionsDataContext.Provider
      value={{
        adminFormSubmissionsData,
        setAdminFormSubmissionsData,
        adminFormSubmissionsStatusTotals,
        setAdminFormSubmissionsStatusTotals,
        isAdminFormSubmissionsDataLoaded,
        setIsAdminFormSubmissionsDataLoaded,
        adminFormSubmissionsDataOptions,
        setAdminFormSubmissionsDataOptions,
        setForceRefresh,
        adminFormSubmissionsDataTotalCount
      }}
    >
      {children}
    </AdminFormSubmissionsDataContext.Provider>
  );
}