import { createTheme } from '@mui/material/styles';
import { inceptiaGreenAlphaColors } from '../utilities/CSS';

declare module '@mui/material/styles' {
  interface Palette {
    InceptiaGreen: Palette['primary'];
  }

  interface PaletteOptions {
    InceptiaGreen?: PaletteOptions['primary'];
  }
}

const inceptiaGreen = {
  main: '#006D3F',
  light: '#43AD43',
  dark: '#307A30',
  contrastText: '#FFFFFF'
};

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    secondary: {
      light: "#FFF",
      main: "#FFF",
      dark: "#CCC",
    },
    InceptiaGreen: inceptiaGreen,
    text: {
      primary: '#424242'
    },
    background: {
      default: 'rgb(248, 249, 252)'
    }
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover, &:focus-visible': {
            backgroundColor: inceptiaGreenAlphaColors.hover
          },
          '&.Mui-selected': {
            backgroundColor: inceptiaGreenAlphaColors.selected,
            color: inceptiaGreen.main,
          },
          '&.Mui-selected:hover, &.Mui-selected:focus-visible': {
            backgroundColor: inceptiaGreenAlphaColors.selectedHover
          }
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: inceptiaGreen.main,
          },
          textTransform: "none",
          minWidth: 150,
          width: 150,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: "var(--text-color)",
            "&.Mui-disabled": {
              color: "var(--text-color)"
            },
            "&.Mui-focused": {
              // Need to darken the color of the label when the input has focus just enough to get it to pass WAVE's accessibility contrast requirements
              // Original color: #378b36
              color: '#327f31'
            },
            "&.Mui-focused.Mui-error": {
              // Input's label that has an error should be red even when it has focus
              color: "var(--error-color)"
            }
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: '#378B36'
            },
            "&.Mui-focused.Mui-error fieldset": {
              // Input's border that has an error should be red even when it has focus
              borderColor: "var(--error-color)"
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked:not(.Mui-disabled), &.MuiCheckbox-indeterminate:not(.Mui-disabled)': {
            color: 'inherit',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          // Controls checked color for the thumb
          "&.Mui-checked": {
            color: inceptiaGreen.main
          }
        },
        track: {
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: inceptiaGreen.main
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#eee'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked:not(.Mui-disabled), &.MuiCheckbox-indeterminate:not(.Mui-disabled)': {
            color: inceptiaGreen.main
          }
        }
      }
    }
  }
});

export const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });