import { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { cardSX, pageHeaderSX } from '../utilities/CSS';
import AdminUsersTable, { AdminSearchUsers } from './AdminUsersTable';

export default function AdminCenterUsers() {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <>
      <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 4.5 }}>Users</Typography>

      <Paper sx={{ ...cardSX.paper, mb: 3.75 }}>
        <AdminSearchUsers searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Paper>

      <Paper sx={cardSX.paper}>
        <AdminUsersTable searchQuery={searchQuery} />
      </Paper>
    </>
  );
}
