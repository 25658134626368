import { useContext, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CloseIcon from '@mui/icons-material/Close';
import { CurrentFormContext } from './ToDoList';
import { UploadFilesEvent, ClearFilesEvent, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import { InceptiaLight } from '../themes/surveyjs-inceptia-theme';
import formsService from "../services/FormsService";
import { iDocumentDTO, iSubmission, iTodo } from '../utilities/APIInterfaces';
import { AutoSaveState, Status } from '../utilities/FormEnums';
import { UserContext } from '../contexts/UserContext';
import { useSnackbar } from 'notistack';
import userService from '../services/UserService';
import documentService from '../services/DocumentService';
import { renameFile } from '../utilities/HelperFunctions';
import KeycloakService from '../services/KeycloakService';

type Props = {
  openForm: iTodo | null;
  survey: Model;
  setTodoListView: React.Dispatch<React.SetStateAction<'active' | 'completed'>>;
};

let autoSaveState : AutoSaveState = AutoSaveState.INITIAL;
let isRetakingForm = false;
let switchToActiveTab = false;
let submissionId: number | undefined | null;

export default function OpenFormModal({
  openForm,
  survey,
  setTodoListView
}: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  survey.applyTheme(InceptiaLight);

  const userContext = useContext(UserContext);
  const { openFormIsOpen, setOpenFormIsOpen } = useContext(CurrentFormContext);
  let submissionDocuments: iDocumentDTO[] = [];

  const { enqueueSnackbar } = useSnackbar();
  
  function OnSurveyCommitHandler(sender : any, options: any) {
    const data = survey.data;
    data.pageNo = survey.currentPageNo;
    autoSaveState = AutoSaveState.DATA_NOT_CHANGED;

    SaveSurveyDataAsync(
    {
      id:  submissionId || -1,
      uuid: openForm?.form_Submission_Uuid,
      forms_Version_Id: openForm?.form_Version_Id || -1,
      submitted_By: userContext.user?.id || -1,
      submission_Json: JSON.stringify(data),
      submission_Type_Id: openForm?.submission_Type_Id || 1, // default to student
      org_Id: openForm?.org_Id || -1,
      status_Id: Status.SUBMITTED,
      documents: submissionDocuments,
      is_Retaking_Form: isRetakingForm,
      is_Submitting_Form: true
    });
    
    if (switchToActiveTab) setTodoListView('active');
    setOpenFormIsOpen(false);
    autoSaveState = AutoSaveState.INITIAL;
  };

  function SurveyDataChanged () {
    if (autoSaveState !== AutoSaveState.LOADING) {
      autoSaveState = AutoSaveState.SAVE_NEEDED;
    }
  }

  function SaveSurveyData () {

    if (autoSaveState === AutoSaveState.SAVE_NEEDED) {
      if (isRetakingForm) switchToActiveTab = true;
      const data = survey.data;
      data.pageNo = survey.currentPageNo;
      autoSaveState = AutoSaveState.DATA_NOT_CHANGED;

      SaveSurveyDataAsync(
      {
        id: submissionId || -1,
        uuid: openForm?.form_Submission_Uuid,
        forms_Version_Id: openForm?.form_Version_Id || -1,
        submitted_By: userContext.user?.id || -1,
        submission_Json: JSON.stringify(data),
        submission_Type_Id: openForm?.submission_Type_Id || 1, // default to student
        org_Id: openForm?.org_Id || -1,
        status_Id: Status.INCOMPLETE,
        documents: submissionDocuments,
        is_Retaking_Form: isRetakingForm,
        is_Submitting_Form: false
      });
    }
  }

  async function GetSurveyDataAsync() {

    // If form's status is declined or ineligible and the forms_version.CAN_RETAKE field is true, open the form "fresh" (without the previous content).
    if (openForm?.status_Id && openForm.can_Retake && [Status.DECLINED, Status.INELIGIBLE].includes(openForm.status_Id)) {
      survey.data = null;
      survey.currentPageNo = 0;
      autoSaveState = AutoSaveState.DATA_NOT_CHANGED;
      isRetakingForm = true;
      return null;
    }

       let authId = userContext.user?.authId?.toString() || "";

       autoSaveState = AutoSaveState.LOADING;

      userService.GetUserSubmission(
        authId,
        openForm?.form_Submission_Uuid || "",
        (v) => v
      ).then((result) => {
        if (result) {
          let origSubmissionData:iSubmission = result;
          submissionDocuments = origSubmissionData.documents;
          let data = JSON.parse(origSubmissionData.submission_Json);
          survey.data = data;
          submissionId = origSubmissionData.id;
          if (data.pageNo) {
            survey.currentPageNo = data.pageNo;
          }
        } else {
          survey.data = null;
          survey.currentPageNo = 0;
          submissionId = null;
        }
        autoPopulateIf(survey);
        autoSaveState = AutoSaveState.DATA_NOT_CHANGED;

        return result;
      })
  }

  async function SaveSurveyDataAsync(newSubmissionData : iSubmission) {
    await formsService.SaveFormSubmission(newSubmissionData, (v) => v)
      .then((result) => {
        submissionId = result?.id;
        return result;
      })
      .catch((error) => {
        enqueueSnackbar(error.toString());
      }).finally(() => {
        isRetakingForm = false;
      });
  }

  const removeDeleteFileOptions = (el: HTMLElement) => {
    if (!el) return;

    // Only want to prevent "students" from deleting files - if user belongs to a Keycloak group, they are NOT a student
    if (KeycloakService.getGroups().length > 0) return;

    let clearIconButton: HTMLElement | null = null;
    let filePreview: NodeListOf<HTMLElement> | null = null;
    let cnt = 0;

    // After a file is uploaded, the DOM elements for deleting the file or all files is not immediately added to the DOM
    // So we need to wait for them to be added...
    const tryFindClearIconButton = () => {
      clearIconButton = el.querySelector('#sv-file-clean');
      filePreview = el.querySelectorAll('.sd-file__preview');
      if (clearIconButton && filePreview.length > 0) {
        clearIconButton.remove();
        filePreview.forEach(el => {
          const removeIconButton = el.querySelector('.sd-file__remove-file-button') as HTMLElement;
          if (removeIconButton) removeIconButton.remove();
        });
      } else if (cnt < 10) {
        // Give up after ten 100ms tries (i.e. 1 second)
        setTimeout(tryFindClearIconButton, 100);
        cnt++;
      }
    };
    tryFindClearIconButton();
  };

  survey.onUploadFiles.add(async (_, options) => {
    const formData = new FormData();
    const lastFileIndex = options.files.length - 1;
    let hasValidFiles: boolean = true;

    options.files.forEach(async (file) => {
      await file.text().then(async (x) => {
        if (
          x.includes("Encrypt") ||
          x
            .substring(x.lastIndexOf("<<"), x.lastIndexOf(">>"))
            .includes("/Encrypt")
        ) {
          hasValidFiles = false;
          enqueueSnackbar(`Cannot upload password protected or encrypted file: ${file.name}`);
          options.callback("error");
        } else {
          formData.append("formFileCollection", file);
        }

        if (options.files.indexOf(file) === lastFileIndex && hasValidFiles){
          await surveyUploadFile(formData, options);

          // After each file upload, we MAY want to remove the options for deleting the file
          removeDeleteFileOptions(options.question.rootElement as HTMLElement);
        }
      });
    });
  });

  async function surveyUploadFile(formData: FormData, options: UploadFilesEvent) {
        if (userContext.user?.id && formData.has("formFileCollection")) {
          await documentService.UploadFormFiles(formData, userContext.user?.authId?.toString(), (v) => v)
          // the response is json if successful and text if not.
          // convert the response to text, then try to convert to json.
          .then((response) => response.text())
          .then((body) => {
            try {
              let data : iDocumentDTO[] = JSON.parse(body);
              submissionDocuments.push(...data);
              options.callback(
                "success",
                options.files.map((file) => {
                  let documentObj = data.filter((item: iDocumentDTO) => {return item.original_File_Name === file.name;});
                  return {
                    file: renameFile(file, documentObj[0].file_Name),
                    content: process.env.REACT_APP_DASHBOARD_API_URL + "/Document/" + documentObj[0].uuid
                  };
                })
              );
            } catch (error) {
              throw Error((error as Error).message);
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.toString());
            console.error("Error: ", error);
            options.callback("error");
          });
        }
  }
  
  async function surveyDeleteFile(fileURL: string, options: ClearFilesEvent) {
    await documentService.DeleteFormFile(fileURL, (v) => v)
      .then((response) => {
        if (response.status === 200) {
          if (submissionDocuments.length) {
            const fileUuid = fileURL.substring(fileURL.lastIndexOf('/') + 1);
            submissionDocuments = submissionDocuments.filter((item: iDocumentDTO) => { return item.uuid !== fileUuid; });
          }
          enqueueSnackbar(`File was deleted successfully`, { variant: 'success' });
          // Inceptia WANTS the document to remain in the JSON even after delete
          // Goal is to prevent deletes in the future
          options.callback("error");
        } else {
          options.callback("error");
          throw Error(`Failed to delete file: ${fileURL}`);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString());
        console.error("Error: ", error);
        options.callback("error");
      });      
  }

  survey.onClearFiles.add(async (_, options) => {
    if(options.question.getType() === "file") {
      if (!options.value || options.value.length === 0)
          return options.callback("success");
      if (!options.fileName && !!options.value) {
          for (const item of options.value) {
              await surveyDeleteFile(item.content, options);
          }
      } else {
          const fileToRemove = options.value.find(
              (item: { name: string; }) => item.name === options.fileName
          );
          if (fileToRemove) {
              await surveyDeleteFile(fileToRemove.content, options);
          } else {
              console.error(`File with name ${options.fileName} was not found`);
          }
      }
    }
  });

  function autoPopulateIf(surveyObj: Model) {
    const mapping = formsService.GetAutofillMapBy(userContext?.user, openForm?.org_Id ?? 0);
    Object.entries(mapping).map((kvp) => {
      let question = surveyObj.getQuestionByName(kvp.at(0) ?? "");
      if (question) {
        question.value = kvp.at(1) ?? "";
      }
    });
  }

  async function onLoadedSurveyFromServiceHandler() {
    GetSurveyDataAsync();
  }

  survey.onLoadedSurveyFromService.add(onLoadedSurveyFromServiceHandler);
  survey.onComplete.add(OnSurveyCommitHandler);
  survey.onValueChanged.add(SurveyDataChanged);
  survey.onCurrentPageChanged.add(SaveSurveyData);

  survey.onAfterRenderQuestion.add((_, options) => {
    if (options.question.getType() === 'file') removeDeleteFileOptions(options.htmlElement);
  });

  useEffect(() => {

    if (openFormIsOpen && autoSaveState === AutoSaveState.INITIAL) {
      GetSurveyDataAsync();
    }
  }, [openFormIsOpen]);

    
  const handleClose = () => {
    if (autoSaveState === AutoSaveState.SAVE_NEEDED) switchToActiveTab = true;
    
    // if the handler fires more than once
    SaveSurveyData();
    setOpenFormIsOpen(false);
    autoSaveState = AutoSaveState.INITIAL;
  
    if (switchToActiveTab) setTodoListView('active');
  }

  return (
    <Dialog
      open={openFormIsOpen}
      onClose={handleClose}
      maxWidth='lg'
      fullScreen={fullScreen}
      PaperProps={{
        sx: { borderRadius: { xs: 0, md: 2 }}
      }}
      sx={{
        height: { xs: '100%', md: 'initial' },
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: {sx: "100%", md: "90%" },
            maxWidth: "1175px",
          },
        },        
      }}
      aria-labelledby='form-modal-title'
      aria-describedby='form-modal-content'
    >
      <DialogTitle id='form-modal-title' sx={{ p: 1, display: 'flex' }}>
        {/* Fix "Empty header" accessibility error by adding a "visually hidden" element for the dialog title */}
        <Typography variant='body1' sx={visuallyHidden}>Open Form Modal</Typography>
        <IconButton aria-label='Close' onClick={handleClose} sx={{ ml: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent id='form-modal-content' sx={{
        mb: 0,
        p: 0,

        // Fallback in case the removeDeleteFileOptions() doesn't find the elements to remove them
        ...(KeycloakService.getGroups().length === 0 ? {
          '& #sv-file-clean': {
            display: 'none'
          },
          '& .sd-file__preview .sd-file__remove-file-button': {
            display: 'none !important'
          }
        } : {})
      }}>
        <Survey model={survey} />
      </DialogContent>

    </Dialog>
  )
}
