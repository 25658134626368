import DashboardAPI from "../apis/DashboardAPI";
import { enqueueSnackbar } from "notistack";

class OrgService {

    async CheckForValidPURL (purl: string | undefined, loader: (v: boolean) => void) : Promise<boolean> {
        loader(true);
        let isValid = await DashboardAPI.callForData<boolean>({
            method: "GET",
            route: `${this.GetAPIPrefix()}/CheckValidPurl/${purl}`
        });
        loader(false);
        return isValid;
    }

  //returns the orgs based on the user's token
    async GetAllowedOrganizations( loader: (v: boolean) => void) {

    loader(true);
    let result = await DashboardAPI.callForData({
      method: "GET",
      route: `${this.GetAPIPrefix()}`
    });
    loader(false); 

    return result;
  }

    GetAPIPrefix(): string {
        let apiPrefix: string = 'Org';
        return apiPrefix;
    }
}

const orgService = new OrgService()
export default orgService;
