import { Box, Paper, Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { cardSX } from '../utilities/CSS';

let ariaPrefix = '';

type CustomTabPanelProps = {
  autoHeight?: boolean;
  children?: React.ReactNode;
  index: number;
  selectedTab: number;
};
export const CustomTabPanel = (props: CustomTabPanelProps) => {
  const { children, selectedTab, index, autoHeight, ...other } = props;

  return (
    <Box
      id={`${ariaPrefix}-tabpanel-${index}`}
      aria-labelledby={`${ariaPrefix}-tab-${index}`}
      hidden={selectedTab !== index}
      role='tabpanel'
      {...other}
    >
      {selectedTab === index && (
        // By default we want the min height defined in cardSX - but we can override if needed with autoHeight
        <Paper sx={autoHeight ? cardSX.paper : cardSX.paperMinHeight }>
          {children}
        </Paper>
      )}
    </Box>
  );
};

type CustomTabsProps = {
  ariaTabAndPanelPrefix: string;
  selectedTab?: number;
  tabs: TabProps[];
  handleTabChange: (event: React.SyntheticEvent, newTab: number) => void;
} & TabsProps;
const CustomTabs = ({ ariaTabAndPanelPrefix, selectedTab, tabs, handleTabChange, ...other }: CustomTabsProps) => {
  ariaPrefix = ariaTabAndPanelPrefix;
  return (
    <Tabs
      value={selectedTab ? selectedTab : 0}
      onChange={handleTabChange}
      TabIndicatorProps={{
        sx: {
          bgcolor: 'InceptiaGreen.main'
        }
      }}
      sx={{ mb: 3 }}
      {...other}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          id={`${ariaTabAndPanelPrefix}-tab-${index}`}
          aria-controls={`${ariaTabAndPanelPrefix}-tabpanel-${index}`}
          {...tab}
        />
      ))}
    </Tabs>
  );
};
export default CustomTabs;