import { useEffect } from 'react';

// Using a modified version of the code found here - https://codesandbox.io/p/sandbox/formik-auto-focus-first-error-lbhn9
// to automatically focus on the first formik/yup error.

type ErrorObject = {
  [key: string]: ErrorObject | string;
};

type useFormikFirstErrorFocusOptions = {
  formik: any;
};

const useFormikFirstErrorFocus = ({ formik }: useFormikFirstErrorFocusOptions) => {
  useEffect(() => {
    const isObject = (obj: unknown): obj is ErrorObject => {
      return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
    };

    const getFirstErrorKey = (object: ErrorObject, keys: string[] = []): string | undefined => {
      const firstErrorKey = Object.keys(object)[0];
      const value = object[firstErrorKey];

      if (typeof value === 'string') return [...keys, firstErrorKey].join('.');

      if (isObject(value)) return getFirstErrorKey(value, [...keys, firstErrorKey]);

      return undefined;
    };

    if (!formik.isValid && formik.submitCount !== 0 && formik.isSubmitting) {
      const firstErrorKey = getFirstErrorKey(formik.errors);
      if (firstErrorKey && document.getElementById(firstErrorKey)) {
        document.getElementById(firstErrorKey)?.focus();
      }
    }
  }, [formik.submitCount, formik.isValid, formik.errors, formik.isSubmitting]);
};

export default useFormikFirstErrorFocus;