import { ReactNode } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { inceptiaGreenAlphaColors } from '../utilities/CSS';

type ConfirmationDialogProps = {
  ariaPrefix: string;
  dialogTitle: string;
  singleButton: boolean;
  ctaButtonText: string;
  ctaButtonWorkingText: string;
  cancelButtonText?: string;
  children: ReactNode;
  open: boolean;
  setOpenDialog: (value: boolean) => void;
  isWorking?: boolean;
  setIsWorking?: (value: boolean) => void;
  width?: number;
  dialogSX?: {
    title?: {};
    content?: {};
    actions?: {};
  };
}

const ConfirmationDialog = ({
  ariaPrefix,
  dialogTitle,
  singleButton,
  ctaButtonText,
  ctaButtonWorkingText,
  cancelButtonText = 'Cancel',
  children,
  open,
  setOpenDialog,
  isWorking = false,
  setIsWorking = () => {},
  width,
  dialogSX
}: ConfirmationDialogProps) => {

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCTAButtonClick = () => {
    setIsWorking(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          width: width ? width : 425,
        }
      }}
      aria-labelledby={`${ariaPrefix}-modal-title`}
      aria-describedby={`${ariaPrefix}-modal-content`}
    >
      <DialogTitle
        id={`${ariaPrefix}-modal-title`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          pl: 3,
          ...dialogSX?.title
        }}
      >
        {dialogTitle}
        <IconButton aria-label='Close' onClick={handleClose} sx={{ ml: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent id={`${ariaPrefix}-modal-content`} sx={dialogSX?.content}>
        {children}
      </DialogContent>

      <DialogActions sx={{
        color: 'InceptiaGreen.main',
        justifyContent: 'center',
        p: 3,
        pb: 2.5,
        ...dialogSX?.actions
      }}>

        {/* Option to have a single button */}
        {! singleButton && (
          <Button
            variant='outlined'
            color='inherit'
            disabled={isWorking ? isWorking : false}
            sx={{
              fontSize: '1rem',
              flexBasis: '50%',
              textTransform: 'none',
              '&:hover, &:focus': {
                backgroundColor: inceptiaGreenAlphaColors.hover
              }
            }}
            onClick={handleClose}
          >{cancelButtonText}</Button>
        )}
        <Button 
          variant='contained' 
          disabled={isWorking ? isWorking : false}
          sx={{
            fontSize: '1rem',
            flexBasis: '50%',
            textTransform: 'none',
            bgcolor: 'InceptiaGreen.main',
            '&:hover': {
              backgroundColor: 'InceptiaGreen.dark',
            }
          }}
          onClick={singleButton? handleClose : handleCTAButtonClick}
        >
          {isWorking ? (
            <>
              <CircularProgress
                color='inherit'
                size={20}
                sx={{ mr: 1.5 }}
              />
              {ctaButtonWorkingText}
            </>
          ) : (ctaButtonText)}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
